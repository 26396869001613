import './assets/css/App.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import "./index.css";
import { GoogleOAuthProvider } from '@react-oauth/google';


import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <GoogleOAuthProvider clientId={"473073564461-4jpmni30pp6bdludnesh29i1n7772unr.apps.googleusercontent.com"}>
  <BrowserRouter>
    <App />
    </BrowserRouter>,
  </GoogleOAuthProvider>

);
