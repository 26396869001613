import Card from "components/card/Card";
import BarChart from "components/charts/BarChart";
import { MdBarChart } from "react-icons/md";
import { useEffect, useState, useContext } from "react";
import axios from "axios";
import { getTotalCost } from "variables/charts";
import Menu from 'components/menu/MainMenu';
import Datepicker from "react-tailwindcss-datepicker"; 
import { Flex, Select, SimpleGrid } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import api from "api"
import csrf from "csrf"
import { updateCategories, updateData, updateDateRange, updateInstance, updateIsChartLoading, updateRegion, updateRegions, updateService, updateServices } from "features/consumption/ChartSlice";
import {
  barChartDataWeeklyRevenue,
  barChartOptionsWeeklyRevenue,
} from "variables/charts";
import consumptionSlice from "features/consumption/consumptionSlice";

console.log("inintial  barChartOptionsWeeklyRevenue", barChartOptionsWeeklyRevenue)
console.log("inintial  barChartDataWeeklyRevenue", barChartDataWeeklyRevenue)

const WeeklyRevenue = ({ service, region, isLoading, chartData, chartOptions, granularity, changeGranularity, changeLoading, dateRange, changeDateRange, ...rest }) => {

  const chart = useSelector((state) => state.chart)
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const { w } = rest


  const handleValueChange = (newValue) => {
    console.log("newValue:", newValue);
    // if(newValue["startDate"])
    if (newValue["startDate"] != null && newValue["endDate"] != null) {
      dispatch(updateIsChartLoading(true))
      dispatch(updateDateRange({ ...newValue }))

    }
    console.log("dateRange", dateRange)
    console.log("oui")
  } 

  function handdleServiceChange(e) {
    console.log("sercice name", e.target.value);
    dispatch(updateService(e.target.value))
    dispatch(updateIsChartLoading(true))

  }

  function handleRegionChange(e) {
    console.log("Region names", e.target.value);
    dispatch(updateRegion(e.target.value))
    dispatch(updateIsChartLoading(true))

  }

  function handleInstanceChange(e) {
    console.log("instance names", e.target.value);
    dispatch(updateInstance(e.target.value))
    dispatch(updateIsChartLoading(true))

  }

  console.log("WeeklyRevenue rest paramaters", w)

  useEffect(() => {
    const getServicesRegions = async (csrf) => {
      try {
        let response = await api.get('/filter_list', {
          withCredentials: true,
          headers: {
            "csrf-token": csrf.token
          }
        })
        if (response.status == 200) {
          console.log("services", response.data["services"]);
          console.log("regions", response.data["regions"]);
          dispatch(updateServices(["All", ...response.data["services"]]))
          dispatch(updateRegions(["All", ...response.data["regions"]]))
          // setServices(["All", ...response.data["services"]])
          // setRegions(["All", ...response.data["regions"]])
        }
      } catch (error) {
        console.log(error)
      }

    }

    const getCostUsage = async (csrf) => {
      try {
        let response = await api.get('/cost_usage', {
          withCredentials: true,
          headers: {
            "csrf-token": csrf.token
          }
        })
        if (response.status == 200) {
          let costs = response.data
          console.log("total costs", costs);
          barChartOptionsWeeklyRevenue["xaxis"]["categories"] = []
          barChartDataWeeklyRevenue[0]["data"] = []
          for (let index = 0; index < costs.length; index++) {
            barChartOptionsWeeklyRevenue["xaxis"]["categories"][index] = chart?.granularity != "Monthly" ? costs[index]["time"].split("-")[2] + "/" + costs[index]["time"].split("-")[1] : costs[index]["time"];
            barChartDataWeeklyRevenue[0]["data"][index] = parseFloat(costs[index]["total"]).toFixed(2) || 0;
          }
          dispatch(updateCategories([...barChartOptionsWeeklyRevenue["xaxis"]["categories"]]))
          dispatch(updateData([...barChartDataWeeklyRevenue[0]["data"]]))
          dispatch(updateIsChartLoading(false))
          console.log("barChartOptionsWeeklyRevenue in 2nd useEffect", barChartOptionsWeeklyRevenue["xaxis"]["categories"])
          console.log("barChartDataWeeklyRevenue 2nd useEffect", barChartDataWeeklyRevenue[0]["data"])
        }
      } catch (error) {

      }

    }

    // getCostByService()
    // changeLoading(false)
    csrf.load_hashed_csrf_token()
    csrf.verify_token()
    console.log("auth", auth)
    console.log("chart", chart)
    if (auth?.hasAwsAccount) {
      if (chart?.services.length == 0 || chart?.regions.lenghth == 0)
        getServicesRegions(csrf)
      if (chart?.categories.length === 0 || chart?.data.length == 0)
        getCostUsage(csrf)
    }
    // getCostUsage(csrf)
    // getServicesRegions(csrf)

  }, [auth?.hasAwsAccount]);

  useEffect(() => {
    const getCostByFilter = async () => {
      try {
        let response = await api.post('/cost_by_filter', {
          "service": chart?.service,
          "region": chart?.region,
          "granularity": chart?.granularity.toUpperCase(),
          "instance": chart?.instance,
          "dateRange": chart?.dateRange
        }, {
          withCredentials: true,
          headers: {
            "csrf-token": csrf.token
          }
        })
        if (response.status == 200) {
          let costs = response.data
          console.log("costs by filter", costs);
          barChartOptionsWeeklyRevenue["xaxis"]["categories"] = []
          barChartDataWeeklyRevenue[0]["data"] = []
          for (let index = 0; index < costs.length; index++) {
            barChartOptionsWeeklyRevenue["xaxis"]["categories"][index] = chart?.granularity != "Monthly" ? costs[index]["time"].split("-")[2] + "/" + costs[index]["time"].split("-")[1] : costs[index]["time"];
            barChartDataWeeklyRevenue[0]["data"][index] = parseFloat(costs[index]["total"]).toFixed(2) || 0;
          }
          dispatch(updateCategories([...barChartOptionsWeeklyRevenue["xaxis"]["categories"]]))
          dispatch(updateData([...barChartDataWeeklyRevenue[0]["data"]]))
          dispatch(updateIsChartLoading(false))
          console.log("barChartOptionsWeeklyRevenue in 2nd useEffect", barChartOptionsWeeklyRevenue["xaxis"]["categories"])
          console.log("barChartDataWeeklyRevenue 2nd useEffect", barChartDataWeeklyRevenue[0]["data"])
        }
      } catch (error) {
        console.log(error)
      }

    }

    if (auth?.hasAwsAccount) {
      console.log("chart in 2nd useEffect", chart)
      getCostByFilter(csrf)
    }
  }, [chart?.service, chart?.region, chart?.granularity, chart?.instance, chart?.dateRange]);

  return (
    <Card extra="flex flex-col  bg-white  rounded-3xl py-6 px-2 text-center" w={w}>
      <SimpleGrid
        columns={{ sm: 1, md: 3, lg: 3, xl: 3, '2xl': 3 }}
        gap="20px"
        mb="30px"
      >
        <Flex direction="column" >
          <h1 className='ml-5 mb-3 font-bold' >Service</h1>
          <Select onChange={handdleServiceChange}
            fontSize='sm'
            id='color'
            variant='main'
            h='44px'
            maxH='44px'
            fontWeight='400'
            me='20px'
            defaultValue={chart?.service}>
            {chart?.services.map((service, i) => (<option value={service} key={i}> {service} </option>))}
          </Select>
        </Flex>

        <Flex direction="column" >
          <h1 className='ml-5 mb-3 font-bold' >Region</h1>
          <Select onChange={handleRegionChange}
            fontSize='sm'
            id='color'
            variant='main'
            h='44px'
            maxH='44px'
            fontWeight='400'
            me='20px'
            defaultValue={chart?.region}>
            {chart?.regions.map((service, i) => (<option value={service} key={i}> {service} </option>))}

          </Select>
        </Flex>

        <Flex direction="column" >
          <h1 className='ml-5 mb-3 font-bold' >Instance</h1>
          <Select onChange={handleInstanceChange}
            fontSize='sm'
            id='color'
            variant='main'
            h='44px'
            maxH='44px'
            fontWeight='400'
            me='20px'
            defaultValue={chart?.instance}>
            {(chart?.instances.length == 0 ? (<option value={chart?.instance} key="1"> {chart?.instance} </option>) : chart?.instances.map((region, i) => (<option value={chart?.instance} key={i}> {chart?.instance} </option>)))}

          </Select>
        </Flex>

      </SimpleGrid>
      <div className="mb-auto flex items-center justify-between px-6">
        <h2 className="text-lg font-bold text-navy-700 dark:text-white">
          {chart?.granularity} Cost ($)
        </h2>
        <div className="w-1/3 flex flex-row">
          <Datepicker
            primaryColor={"blue"}
            value={dateRange}
            onChange={handleValueChange}
            showShortcuts={true}
            placeholder="Enter Date Range" 
            toggleClassName="absolute bg-blue-500 rounded-lg text-white right-0 h-full px-2 text-gray-400 focus:outline-none disabled:opacity-40 disabled:cursor-not-allowed" 
            inputClassName="relative transition-all duration-300 py-2.5 pl-4 pr-14 w-full border-gray-300 dark:bg-slate-800 dark:text-white/80 dark:border-slate-600 rounded-lg tracking-wide font-light text-sm placeholder-gray-400 bg-white focus:ring disabled:opacity-40 disabled:cursor-not-allowed focus:border-blue-500 focus:ring-blue-500/20 outline rounded"
            containerClassName="relative w-full text-gray-700 border-blue-100"

          /> 
          <Menu />

        </div>
       
      </div>

      <div className="md:mt-16 lg:mt-0">
        <div className="h-[250px] w-full xl:h-[350px]">
          {chart?.isChartLoading ? (
            <div className="" style={{ marginTop: "150px", marginLeft: "290px" }} role="status">
              <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            <BarChart
              // chartData={chart?.chartData}
              // chartOptions={chart?.chartOptions}
              chartData={barChartDataWeeklyRevenue}
              chartOptions={barChartOptionsWeeklyRevenue}
            />
            // <div class="text-center">Time(t){chart?.chartOptions["xaxis"]["categories"][0]}</div>
          )}

        </div>
        {/* <div class="text-center">Time(t){barChartOptionsWeeklyRevenue["colors"][0]}</div> */}
        {/* <div class="text-center">Time(t){chart?.chartData[0]["name"]}</div> */}
        {/* <div class="text-center">Time(t){chart?.chartOptions["colors"][0]}</div> */}

      </div>

      {/* ici {Object.keys(barChartDataWeeklyRevenue)} */}

    </Card>

  );
};

export default WeeklyRevenue;
