import cookieService from "universal-cookie"
const crypto = require('crypto');
var Buffer = require('buffer/').Buffer
const utf8 = require('utf8');

class csrf {

    static SECRET_KEY = process.env.REACT_APP_CSRF_SECRET;

    static hashed_csrf_token = "";

    static token = "";

    static getCookie(cookieKey) {
        const cookieString = document.cookie;
        const cookies = cookieString.split(';').map(cookie => cookie.trim());

        for (const cookie of cookies) {
            const [name, value] = cookie.split('=');
            if (name === cookieKey) {
                return decodeURIComponent(value);
            }
        }

        return null; // Return null if cookie with given key is not found
    }

    static load_hashed_csrf_token() {
        csrf.hashed_csrf_token = csrf.getCookie("csrf_token")
        console.log("csrf.SECRET_KEY", csrf.SECRET_KEY)
        console.log("csrf.hashed_csrf_token", csrf.hashed_csrf_token)
    }


    static verify_token() {
        if (csrf.hashed_csrf_token != "") {
            try {
                // Decode the CSRF token
                let decoded_token = Buffer.from(utf8.decode(csrf.hashed_csrf_token), 'base64');
                let encoder = new TextEncoder(); // utf8 encoding

                // Extract the signature and token
                let signature = decoded_token.slice(0, 32);
                csrf.token = decoded_token.slice(32).toString('utf8');




                // Calculate the expected signature
                let expected_signature = crypto.createHmac('sha256', csrf.SECRET_KEY).update(csrf.token).digest();

                let is_same_signature = true
                // Compare the signatures to verify the token
                if (signature.length !== expected_signature.length) {
                    is_same_signature = false
                }
                else {
                    for (let i = 0; i < signature.length; i++) {
                        if (signature[i] !== expected_signature[i]) {
                            is_same_signature = false
                            break
                        }
                    }
                }
                csrf.token = is_same_signature ? decoded_token.slice(32).toString('utf8') : "vide"

            } catch (error) {
                console.error('Error:', error);
            }
        }
    }
}



export default csrf



