import { createSlice } from '@reduxjs/toolkit'
import UserService from 'UserService';
import {
    barChartDataWeeklyRevenue,
    barChartOptionsWeeklyRevenue,
} from "variables/charts";

// const userService = new UserService()

export const pieChartSlice = createSlice({
    name: 'chart',
    initialState: {
        data: [],
        colors: ['#4318FF', '#6AD2FF', '#6AD2FF', '#FF0000'],
        labels: [],
        proportions: [],
        isPieChartLoading: true
    },
    reducers: {
        updatePieChartData: (state, action) => {
            state.data = action.payload

        },
        updatePieChartColors: (state, action) => {
            state.colors = action.payload

        },
        updatePieChartLabels: (state, action) => {
            state.labels = action.payload

        },
        updatePieChartProportions: (state, action) => {
            state.proportions = action.payload

        },
        updatePieChartLoading: (state, action) => {
            state.isPieChartLoading = action.payload

        },
    },
})

// Action creators are generated for each case reducer function
export const { updatePieChartColors, updatePieChartData, updatePieChartLabels, updatePieChartProportions, updatePieChartLoading } = pieChartSlice.actions

export default pieChartSlice.reducer