import React from 'react';

// Chakra imports
import { Button, Flex, Link, Text } from '@chakra-ui/react';

// Assets
import banner from 'assets/img/product/OverviewBanner.png';

export default function Banner(props: { page: string }) {
	// Chakra Color Mode
	const { page } = props
	return (
		<Flex
			direction='column'
			bgImage={banner}
			bgSize='cover'
			mb='20px'
			py={{ base: '30px' }}
			px={{ base: '20px', md: '40px' }}
			borderRadius='30px'
			h="150px"
		>

			<Text
				fontSize={{ base: '24px', md: '34px' }}
				color='white'
				mb='14px'
				maxW={{
					base: '75%',
					md: '54%',
					lg: '65%',
					xl: '65%',
					'2xl': '50%',
					'3xl': '42%'
				}}
				fontWeight='700'
				lineHeight={{ base: '32px', md: '42px' }}>
				{page == "savings" ? ("Savings Coming Soon") : (<>Stay in control of your AWS<br></br> usage and costs</>)}
			</Text>
			{/* <Text
				fontSize='md'
				color='#E3DAFF'
				maxW={"100%"}
				fontWeight='500'
				mb='40px'
				lineHeight='28px'>
				{page == "savings" ? "Cut cloud expenses, explore savings plans, and discover strategies to reduce costs." : "Set up notifications to keep track on your budget and watch any unexpected usage early"}
			</Text> */}
		</Flex>
	);
}
