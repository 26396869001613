import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import * as location from "./Cloud.json";
import * as success from "./Success.json";
import axios from "axios";
import DashboardsDefault from 'views/admin/dashboards/default';
import { useLocation, useNavigate } from "react-router-dom";



const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: location.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: success.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

const Loader = () => {
    const [data, setData] = useState([]);
    const [loading, setloading] = useState(false);
    const [completed, setcompleted] = useState(false);
    const navigate = useNavigate();
    const location = useLocation()

    useEffect(() => {
        setTimeout(() => {
            navigate(location.state.url)
        }, 500);
    }, []);





    return (
        <>
            {!completed ? (
                <div style={{ background: "#e9ecef", width: "1500px", height: "1500px" }} >

                    {!loading ? (
                        <div style={{ marginTop: "300px", marginLeft: "600px", position: "absolute" }}><Lottie options={defaultOptions1} height={200} width={200} />
                            {/* <h1 className="text-center text-xl" >Loading... </h1> */}
                        </div>

                    ) : (
                        <div >
                        </div>
                    )}
                </div>
            ) : (
                <h1>Erreur dans le chargement</h1>
            )}
        </>
    );
}

export default Loader;
