import { configureStore } from '@reduxjs/toolkit'
import userReducer from 'features/user/userSlice'
import consumptionReducer from 'features/consumption/consumptionSlice'
import chartReducer from 'features/consumption/ChartSlice'
import pieChartReducer from 'features/consumption/PieChartSlice'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web


const store = configureStore({
    reducer: {
        auth: userReducer,
        consumption: consumptionReducer,
        chart: chartReducer,
        pieChart: pieChartReducer
    },
})

export { store }

