/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { useForm } from 'react-hook-form'

import { jwtDecode } from "jwt-decode";




// Assets
import illustration from 'assets/img/auth/auth.png';
import { HSeparator } from 'components/separator/Separator';
import DefaultAuth from 'layouts/auth/variants/Default';
import { NavLink } from 'react-router-dom';
import React, { useEffect } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import axios from "axios";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";
import csrf from 'csrf';
import api from "api"
import { useDispatch, useSelector } from 'react-redux';
import UserService from 'UserService';
import { checkAWSConnexion, checkCompany, loginAction, updateCookieSetIntervalId, updateIsAuthLoading, updateIsAuthenticated } from 'features/user/userSlice';
import { checkUserConnexion } from 'functions/functions';
import { toast } from 'react-toastify';


function SignUp() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const googleBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.200');
  const googleText = useColorModeValue('navy.700', 'white');
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const userService = new UserService()

  const googleHover = useColorModeValue(
    { bg: 'gray.200' },
    { bg: 'whiteAlpha.300' },
  );
  const googleActive = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.200' },
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm()

  function onSubmit(values) {
    console.log(values)
    api.post("/addUser", {
      "email": values.email,
      "firstName": values.firstName,
      "lastName": values.lastName,
      "password": values.password,
      "idToken": null,
    },
      {
        headers: {
          "csrf-token": csrf.token,
        },
        withCredentials: true

      })
      .then((response, resolve) => {
        if (response.status == 200) {
          toast.success("User added successfully", {
            position: "top-right",
            theme: "colored",

          });

          dispatch(loginAction({
            "firstName": response.data.firstName,
            "lastName": response.data.lastName,
            "id": response.data.user_id,
            "email": response.data.email

          }))
          dispatch(updateIsAuthenticated(true))

          userService.hasCompany(csrf).then(data => {
            dispatch(checkCompany(data))
          })

          userService.hasAwsAccount(csrf).then(data => {
            dispatch(checkAWSConnexion(data))
          })
          dispatch(updateIsAuthLoading(false))

          console.log("User added with in the database")
          navigate("/")
        }

      })
      .catch((error) => {
        console.log(error)
      })

  }
  const responseGoogle = (response) => {
    console.log(response);
    const user = jwtDecode(response.credential);
    console.log(user);
    api.post("/addUser", {
      "email": user.email,
      "firstName": user.given_name,
      "lastName": user.family_name,
      "idToken": user.sub,
      "password": null
    }, {
      headers: {
        "csrf-token": csrf.token,
      },
      withCredentials: true


    })
      .then((response, resolve) => {
        if (response.status = 200) {
          toast.success("User added successfully", {
            position: "top-right",
            theme: "colored",

          });
          console.log("User added with Google Auth")
          console.log(response.data)
          localStorage.setItem("firstName", response.data.firstName)
          localStorage.setItem("lastName", response.data.lastName)
          localStorage.setItem("user_id", response.data.user_id)
          console.log("User added with Google Auth")
          navigate("/")
        }

      })
      .catch((error) => {
        console.log(error)
      })

  }
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse);
      // fetching userinfo can be done on the client or the server
      const userInfo = axios
        .get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then(res => {
          const user = res.data;
          api.post("/addUser", {
            "email": user.email,
            "firstName": user.given_name,
            "lastName": user.family_name,
            "idToken": user.sub,
            "password": null
          }, {
            headers: {
              "csrf-token": csrf.token,
            },
            withCredentials: true,
          })
            .then((response, resolve) => {
              if (response.status == 200) {
                console.log(response.data)
                dispatch(loginAction({
                  "firstName": response.data.firstName,
                  "lastName": response.data.lastName,
                  "user_id": response.data.user_id,
                  "email": response.data.email
                }))
                dispatch(updateIsAuthenticated(true))

                userService.hasCompany(csrf).then(data => {
                  dispatch(checkCompany(data))
                })

                userService.hasAwsAccount(csrf).then(data => {
                  dispatch(checkAWSConnexion(data))
                })
                dispatch(updateIsAuthLoading(false))

                console.log("User added with Google Auth")
              }
              navigate("/")
            })
            .catch((error) => {
              console.log(error);
            })
        });
    }
  });
  function get_csrf_token() {
    api.get("/get_csrf_token", { withCredentials: true })
      .then((response, resolve) => {
        console.log(response.data)
        csrf.load_hashed_csrf_token()
        csrf.verify_token()
        console.log("csrf token rendered from Class", csrf.token)
      })
  }

  useEffect(() => {
    get_csrf_token()
    if (auth.isAuthenticated && auth.cookieSetIntervalId == null) {
      let intervalId = checkUserConnexion(csrf, api)
      dispatch(updateCookieSetIntervalId(intervalId))
    }

  }, []);



  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        w="100%"
        maxW="max-content"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '8vh' }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading
            color={textColor}
            fontSize={{ base: '34px', lg: '36px' }}
            mb="10px"
          >
            Sign Up
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email and password to sign up!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <Button
            fontSize="sm"
            me="0px"
            mb="26px"
            py="15px"
            h="50px"
            borderRadius="16px"
            bg={googleBg}
            color={googleText}
            fontWeight="500"
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
            onClick={() => login()}
          >
            <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
            Sign up with Google
          </Button>


          <Flex align="center" mb="25px">
            <HSeparator />
            <Text color={textColorSecondary} mx="14px">
              or
            </Text>
            <HSeparator />
          </Flex>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormControl isInvalid={errors.name}>
              <SimpleGrid
                columns={{ base: 1, md: 2 }}
                gap={{ sm: '10px', md: '26px' }}
              >
                <Flex direction="column">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    First name<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    fontSize="sm"
                    ms={{ base: '0px', md: '4px' }}
                    isRequired={true}
                    placeholder="First name"
                    variant="auth"
                    mb="4px"
                    size="lg"
                    className={errors.firstName ? "!border-red-600	" : ""}
                    {...register('firstName', {
                      required: 'This is required',
                    })}
                  />
                  <p class={errors.firstName ? "text-red-500 text-sm mb-15" : ""}> {errors.firstName && errors.firstName?.message}</p>

                </Flex>
                <Flex direction="column">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Last name<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    placeholder="Last name"
                    mb="4px"
                    size="lg"
                    className={errors.lastName ? "!border-red-600	" : ""}
                    {...register('lastName', {
                      required: 'This is required',
                    })}
                  />
                  <p class={errors.lastName ? "text-red-500 text-sm mb-15" : ""}> {errors.lastName && errors.lastName?.message}</p>

                </Flex>
              </SimpleGrid>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                type="email"
                placeholder="mail@simmmple.com"
                mb="4px"
                size="lg"
                className={errors.email ? "!border-red-600	" : ""}
                {...register('email', {
                  required: 'This is required',
                  minLength: { value: 4, message: 'Minimum length should be 4' },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address"
                  },
                })}
              />
              <p class={errors.email ? "text-red-500 text-sm mb-15" : ""}> {errors.email && errors.email?.message}</p>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '4px' }}
                  placeholder="Min. 8 characters"
                  mb="4px"
                  size="lg"
                  type={show ? 'text' : 'password'}
                  className={errors.password ? "!border-red-600	" : ""}
                  {...register('password', {
                    required: 'This is required',
                    minLength: { value: 8, message: 'Minimum length should be 8' },
                    pattern: {
                      value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/,
                      message: 'Password must contain at least one uppercase letter, one lowercase letter, and one number',
                    },
                  })}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: 'pointer' }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}

                  />
                </InputRightElement>
              </InputGroup>
              <p class={errors.password ? "text-red-500 text-sm mb-35" : ""}> {errors.password && errors.password?.message}</p>

              <Flex justifyContent="space-between" align="center" mb="24px">
                <FormControl display="flex" alignItems="start">
                  <Checkbox
                    id="remember-login"
                    colorScheme="brand"
                    me="10px"
                    mt="3px"
                  />
                  <FormLabel
                    htmlFor="remember-login"
                    mb="0"
                    fontWeight="normal"
                    color={textColor}
                    fontSize="sm"
                  >
                    By creating an account means you agree to the{' '}
                    <Link
                      href="https://simmmple.com/terms-of-service"
                      fontWeight="500"
                    >
                      Terms and Conditions,
                    </Link>{' '}
                    and our{' '}
                    <Link
                      href="https://simmmple.com/privacy-policy"
                      fontWeight="500"
                    >
                      Privacy Policy
                    </Link>
                  </FormLabel>
                </FormControl>
              </Flex>
              <Button
                variant="brand"
                fontSize="14px"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                isLoading={isSubmitting}
                type='submit'
              >
                Create my account
              </Button>
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
          </form>

          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="sm">
              Already a member?
              <NavLink to="/auth/sign-in/default">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Sign in
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignUp;
