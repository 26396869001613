// Chakra imports
import { Button, Flex, FormControl, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { useForm } from 'react-hook-form';
import api from "api"
import csrf from "csrf"
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { updateUser } from 'features/user/userSlice';
import { ToastContainer, toast } from 'react-toastify';

export default function Settings(props: { [x: string]: any }) {
	const { ...rest } = props;
	const auth = useSelector((state: any) => state.auth)
	const dispatch = useDispatch()


	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.600';
	const {
		handleSubmit,
		register,
		formState: { errors, isSubmitting },
	} = useForm()

	const navigate = useNavigate()

	function onSubmit(values: any, defaultValues: any) {
		console.log("update_account", values)
		let email = auth?.isLoggedInWithGoogle ? auth?.user.email : values?.email
		let firstName = values?.firstName || auth?.user.firstName
		let lastName = values?.lastName || auth?.user.lastName
		api.post("/update_account", {
			"email": email,
			"firstName": firstName,
			"lastName": lastName,
		},
			{
				headers: {
					"csrf-token": csrf.token,
				},
				withCredentials: true

			})
			.then((response: any) => {
				if (response.status == 200) {
					toast.success("Account info updated sucessfully !", {
						position: "top-right",
						theme: "colored",

					});
					dispatch(updateUser({
						"firstName": firstName,
						"lastName": lastName,
						"email": email,
						"id": auth?.user?.email
					}))
					// navigate("/admin/main/profile/settings")
				}
			})
			.catch((error) => {
				console.log(error);
			})

	}
	useEffect(() => {
		console.log("auth?.isLoggedInWithGoogle", auth?.isLoggedInWithGoogle)
	}, [auth?.isLoggedInWithGoogle, auth?.user])

	return (
		<form onSubmit={handleSubmit(onSubmit)} noValidate>
		<FormControl  >
			<Card {...rest}>
				<Flex direction='column' mb='40px' ms='10px'>
					<Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
						Account Settings
					</Text>
					<Text fontSize='md' color={textColorSecondary}>
						Here you can change user account information
					</Text>
				</Flex>
					<InputField mb='25px' id='email' label='Email Address' placeholder='mail@simmmple.com' defaultValue={auth?.isLoggedInWithGoogle ? auth.user?.email + " (Google)" : auth.user?.email + ""}
						register={register('email', {
							required: auth?.isLoggedInWithGoogle ? false : auth.user?.email ? false : "This is required",
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: "invalid email address"
							}
						})} errors={errors} isDisabled={auth?.isLoggedInWithGoogle} />
					<InputField mb='25px' me='30px' id='firstName' label='First Name' placeholder='John' defaultValue={auth.user?.firstName}
						register={register('firstName', { required: auth.user?.firstName ? false : 'This is required', })} errors={errors} />
					<InputField mb='25px' id='lastName' label='Last Name' placeholder='Doe' defaultValue={auth.user?.lastName}
						register={register('lastName', { required: auth.user?.lastName ? false : 'This is required' })}
						errors={errors} />

					<Button type="submit" variant='brand' minW='183px' fontSize='sm' fontWeight='500' ms='auto'>
					Save changes
				</Button>
			</Card>
			</FormControl>
		</form>
	);
}
