import { createSlice } from '@reduxjs/toolkit'
import UserService from 'UserService';

// const userService = new UserService()

export const consumptionSlice = createSlice({
    name: 'consumption',
    initialState: {
        total: "0",
        isTotalLoading: true,
        lastMonthCost: "0",
        isLastMonthCostLoading: true,
        currentMonthCost: "0",
        isCurrentMonthCostLoading: true,
    },
    reducers: {
        updateTotal: (state, action) => {
            state.total = action.payload

        },
        updateIsTotalLoading: (state, action) => {
            state.isTotalLoading = action.payload

        },
        updateLastMonthCost: (state, action) => {
            state.lastMonthCost = action.payload
        },
        updateIsLastMonthLoading: (state, action) => {
            state.isLastMonthCostLoading = action.payload

        },
        updateCurrentMonthCost: (state, action) => {
            state.currentMonthCost = action.payload
        },
        updateIsCurrentMonthLoading: (state, action) => {
            state.isCurrentMonthCostLoading = action.payload

        },

    },
})

// Action creators are generated for each case reducer function
export const { updateTotal, updateLastMonthCost, updateCurrentMonthCost, updateIsTotalLoading, updateIsLastMonthLoading, updateIsCurrentMonthLoading } = consumptionSlice.actions

export default consumptionSlice.reducer