// Chakra imports
import {
	Flex, FormControl, Modal, ModalContent, ModalOverlay, SimpleGrid, Text, useColorModeValue, ModalBody, Box, Button, useDisclosure, ModalHeader, ModalCloseButton
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import SetUp from 'components/actions/SetUp';
import * as mail from "assets/img/others/mail.json";
import Lottie from "react-lottie";
import * as cloud from "assets/img/others/Cloud.json";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserService from 'UserService';
import csrf from "csrf"
import axios from "axios";
import api from "api";
import { NavLink, useLocation } from 'react-router-dom';


import { GoogleLogin, useGoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';


interface lottieOptionsType {
	animationData: any
}

const defaultOptions: any = {
	loop: true,
	autoplay: true,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
	},
	animationData: mail

};


export default function Connected(props: { [x: string]: any }) {
	const { ...rest } = props;
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
	const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
	const auth = useSelector((state: any) => state.auth)
	const dispatch = useDispatch()
	const [isChangingEmailAddress, SetIsChangingEmailAddress] = useState(false)
	const userService = new UserService()
	const location = useLocation();
	const params = new URLSearchParams(location.search);
	const confirmEmail = params.get('confirmEmail');


	const login = useGoogleLogin({
		onSuccess: (tokenResponse) => {
			console.log(tokenResponse);
			// fetching userinfo can be done on the client or the server

			const userInfo = axios
				.get('https://www.googleapis.com/oauth2/v3/userinfo', {
					headers: { Authorization: `Bearer ${tokenResponse.access_token}` }
				})
				.then(res => {
					const user = res.data;
					api.post("/login", {
						"idToken": user.sub,
					}, {
						headers: {
							"Content-Type": "application/json",
							"csrf-token": csrf.token,
						},
					})
						.then((response: any) => {

							// console.log(response.data)
							// if (response.data.present != undefined && response.data.present == "true") {
							// 	localStorage.setItem("firstName", response.data.firstName)
							// 	localStorage.setItem("lastName", response.data.lastName)
							// 	localStorage.setItem("user_id", response.data.user_id)
							// 	dispatch(loginAction({
							// 		"firstName": response.data.firstName,
							// 		"lastName": response.data.lastName,
							// 		"id": response.data.user_id,
							// 		"email": response.data.email
							// 	}))
							// 	dispatch(updateIsAuthenticated(true))
							// 	let has_company = false
							// 	userService.hasCompany(csrf).then(data => {
							// 		has_company = data
							// 		console.log("has_company", has_company)
							// 		dispatch(checkCompany(has_company))
							// 		console.log(auth)
							// 	}).catch((error) => console.log(error))

							// 	userService.hasAwsAccount(csrf).then(data => {
							// 		dispatch(checkAWSConnexion(data))
							// 	}).catch((error) => console.log(error))
							// 	userService.getAvatar(csrf).then(data => {
							// 		if (data["image"] != "") {
							// 			const binaryData = hexStringToBinary(data["image"]);
							// 			const blob = new Blob([binaryData], { type: 'image/jpeg' });
							// 			let avatarUrl = URL.createObjectURL(blob)
							// 			dispatch(updateAvatar(avatarUrl))
							// 		}
							// 	}).catch((error) => console.log(error))
							// 	dispatch(updateIsAuthLoading(false))
							// 	dispatch(updateIsLoggedInWithGoogle(true))
							// 	if (state != null)
							// 		navigate("/connexion", { state: { redirect: state.redirect } })
							// 	else if (redirectPath != null)
							// 		navigate("/connexion", { state: { redirect: redirectPath } })
							// 	else
							// 		navigate("/connexion")
							// }
							// else {

							// 	console.log("ici", response)
							// 	toast.error("User non present in the database!", {
							// 		position: "top-right",
							// 		theme: "colored",

							// 	});
							// }
						})
						.catch((error) => {
							console.log(error);
						})
				});
		}
	});

	const requestEmailChange = async (csrf: any, email: string) => {
		let data = await userService.changeGmailAddress(csrf, email)
		if (data == "Mail Sent")
			SetIsChangingEmailAddress(true)
		else
			SetIsChangingEmailAddress(false)

	}
	const modalMessage = (): any => {
		if (!isChangingEmailAddress)
			return (
				<Text textAlign="center" color="black" fontSize={{ sm: 'sm', lg: 'sm', xl: 'sm' }}>Change <strong>{auth?.user?.email}</strong> as my gmail address ?</Text>

			)
		else
			return (
				<Text textAlign="center" color="black" fontSize={{ sm: 'sm', lg: 'sm', xl: 'sm' }}>A mail has been sent to <strong>{auth?.user?.email}.</strong> Please Check your spam if you do not see it</Text>
			)

	};
	useEffect(() => {
		csrf.load_hashed_csrf_token()
		csrf.verify_token()
		console.log("confirmEmail", confirmEmail)
		// if (confirmEmail != null)
		// login()
		console.log("isLoggedInWithGoogle", auth?.user?.isLoggedInWithGoogle)
	}, [isChangingEmailAddress])
	// Chakra Color Mode
	return (
		<FormControl>
			<Card px='30px' {...rest}>
				<Modal isOpen={isOpen} onClose={onClose}>
					<ModalOverlay />
					<ModalContent mx='8px' mt="14.75rem" bg='white' boxShadow='unset' w="400px" h="220px !important" borderRadius='10px'
					>
						<ModalHeader color="black">Change Google Account</ModalHeader>
						<ModalCloseButton />
						<ModalBody p='0px' bg='white'  >
							<Flex direction="column" justifyContent="center" alignItems="center" color="white" h="100%" w="100%">
								<Lottie options={defaultOptions} height={100} width={100} />
								<Box px={{ sm: '5', lg: '8', xl: '13' }} >
									{modalMessage()}
								</Box>
								<Box>
									<Flex justify="space-between" mt="24px" gap="10" mb="7">
										<Button
											variant="light"
											fontSize="sm"
											borderRadius="16px"
											w={{ base: '128px', md: '148px' }}
											h="46px"
											onClick={() => {
												SetIsChangingEmailAddress(false)
												onClose()
											}}
											display={isChangingEmailAddress ? "none" : "block"}


										>
											No
										</Button>
										<Button
											variant="darkBrand"
											fontSize="sm"
											borderRadius="16px"
											w={{ base: '128px', md: '148px' }}
											h="46px"
											onClick={() => requestEmailChange(csrf, auth?.user?.email)}
											display={isChangingEmailAddress ? "none" : "block"}

										>
											Yes
										</Button>
										<Button
											variant="darkBrand"
											fontSize="sm"
											borderRadius="16px"
											w={{ base: '128px', md: '148px' }}
											h="46px"
											onClick={() => onClose()}
											display={isChangingEmailAddress ? "block" : "none"}

										>
											OK
										</Button>

									</Flex>
								</Box>
							</Flex>

						</ModalBody>
					</ModalContent>
				</Modal>
				<Flex justify='space-between' align='center'>
					<Text fontSize='2xl' color={textColorPrimary} fontWeight='bold'>
						Connected
					</Text>
				</Flex>
				<SimpleGrid columns={{ sm: 1, md: 1, xl: 1 }} spacing={{ base: '20px', xl: '0px' }}>
					<SetUp
						py='5px'
						borderBottom='1px solid'
						borderColor={"white"}
						name='Google Account'
						value={auth?.isLoggedInWithGoogle ? "Enabled" : "Disabled"}
						actionName='Edit'
						isDisabled={!auth?.isLoggedInWithGoogle}
						onClick={onOpen}
					/>
				</SimpleGrid>
			</Card>
		</FormControl>
	);
}
