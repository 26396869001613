/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormLabel,
  FormControl,
  FormErrorMessage,
  Select,
  SimpleGrid,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useTheme,
  color,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import TagsField from 'components/fields/TagsField';
import Dropzone from 'views/admin/main/ecommerce/newProduct/components/Dropzone';
import React, { useState, useCallback, useEffect } from 'react';
import Lottie from "react-lottie";
import * as note from "../../../../../assets/img/others/note.json";
import * as blog from "../../../../../assets/img/others/Blog.json";
import LoadingOverlay from 'react-loading-overlay-ts';
import BeatLoader from 'react-spinners/BeatLoader';
import api from "api"
import csrf from "csrf"
import UserService from "UserService"
import io from 'socket.io-client';
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form'
import countries from "variables/countries"







// Assets
import { MdOutlineCloudUpload } from 'react-icons/md';
import { verify } from 'crypto';
import { useDispatch, useSelector } from 'react-redux';
import { checkCompany, updateCompany, updateIsAuthLoading } from 'features/user/userSlice';

const NewProduct = () => {
  const navigate = useNavigate();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [activeBullets, setActiveBullets] = useState({
    product: true,
    media: false,
    pricing: false,
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: note.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: blog.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm()

  const productTab = React.useRef()
  const mediaTab = React.useRef()
  const pricingTab = React.useRef()
  const theme = useTheme();
  //eslint-disable-next-line
  const [lineColor, setLineColor] = useState(theme.colors.brand[500]);
  //eslint-disable-next-line
  const [lineColorDark, setLineColorDark] = useState(theme.colors.brand[400]);
  const brand = useColorModeValue(lineColor, lineColorDark);
  const [isAWSConnected, setIsAWSConnected] = useState(false);
  const [awsAccountRole, setAWSAccountRole] = useState("None")
  const [user, setUser] = useState({})
  const [onboardingWindow, setOnBoardingWindow] = useState(null)
  const environment = process.env.REACT_APP_ENVIRONMENT
  const userService = new UserService()
  const [isOnboardingWindowOpen, setIsOnboardingWindowOpen] = useState(false)
  const [roleARN, setRoleARN] = useState("")
  const [roleType, setRoleType] = useState("")
  const [hasCompany, setHasComapny] = useState(false)
  const [company, setCompany] = useState({})
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()


  var socket = io(api.defaults.baseURL, {
    withCredentials: true,
    transports: ['websocket', 'polling']
  });



  const [isActive, setActive] = useState(false)
  const handleButtonClick = useCallback(() => {
    setActive(value => !value)
  }, [])

  const test_socket = () => {
    api.get("/test_socket", {
      withCredentials: true,
      headers: {
        "csrf-token": csrf.token
      }
    }).then((response) => {
      if (response.status == 200) {
        console.log("test_socket status response 200")
      }
    })
  }

  const set_company_info = async (csrf) => {
    if (auth.hasCompany) {
      let company = await userService.get_company(csrf)
      setCompany(company)
    }
  }

  const initUser = async (csrf) => {
    let tmp_user = await userService.verifyUserConnexion(csrf)
    if (tmp_user != null && tmp_user != undefined)
      setUser({ ...tmp_user })
    socket.on('aws', function (data) {
      console.log("aws data called ", data)
      console.log("user.id in first useEffect", tmp_user.id)
      console.log("sent user_id", data["user_id"])
      if (tmp_user.id == data["user_id"]) {
        console.log("onboardingwindow", onboardingWindow)
        setActive(false)
        setAWSAccountRole(data["role_type"])
        setRoleARN(data["role_arn"])
        setRoleType(data["role_type"])
        // change_arn_and_role(data["role_arn"], data["role_type"])
        setIsOnboardingWindowOpen(false)
      }
    });
  }

  let onBoarding = () => {
    setActive(true)
    let klawdyPingParam = environment == "development" ? "param_KlawdyPingBackDevArn" : "param_KlawdyPingBackArn"
    let KlawdyPingBackArn = environment == "development" ? "arn:aws:sns:us-east-1:076610420881:KlawdyPingBackDev" : "arn:aws:sns:us-east-1:076610420881:KlawdyPingBack"
    let templateURL = environment == "development" ? "https://klawdy.s3.amazonaws.com/klawdy-readonly-dev.yaml" : "https://klawdy.s3.amazonaws.com/klawdy-readonly.yaml"
    let onboardingWindow = window.open("https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/quickcreate?templateURL=" + templateURL + "&stackName=KlawdyReadOnlyRole-da3cd21403c0484dbc43439b5c0cc1dd&param_CustomerID=" + user.id + "&param_KlawdyExternalID=afb7111bc4cd6f62cb24776891980a0d9c3a04a16e952101637663ce720cb749&" + klawdyPingParam + "=" + KlawdyPingBackArn + "&param_KlawdyIamRole=arn:aws:iam::076610420881:user/klawdy&param_KlawdyRoleType=READONLY", '_blank');
    setOnBoardingWindow(onboardingWindow)
    setIsOnboardingWindowOpen(true)
    console.log("onboardingWindow in Reading mode", onboardingWindow)
    socket.emit("message", "contenu du message")
  }

  let onBoarding2 = () => {
    setActive(true)
    let klawdyPingParam = environment == "development" ? "param_KlawdyPingBackDevArn" : "param_KlawdyPingBackArn"
    let KlawdyPingBackArn = environment == "development" ? "arn:aws:sns:us-east-1:076610420881:KlawdyPingBackDev" : "arn:aws:sns:us-east-1:076610420881:KlawdyPingBack"
    let templateURL = environment == "development" ? "https://klawdy.s3.amazonaws.com/klawdy-readandwrite-dev.yaml" : "https://klawdy.s3.amazonaws.com/klawdy-readandwrite.yaml"
    let onboardingWindow = window.open("https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/quickcreate?templateURL=" + templateURL + "&stackName=KlawdyReadAndWriteRole-da3cd21403c0484dbc43439b5c0cc1dd&param_CustomerID=" + user.id + "&param_KlawdyExternalID=afb7111bc4cd6f62cb24776891980a0d9c3a04a16e952101637663ce720cb749&" + klawdyPingParam + "=" + KlawdyPingBackArn + "&param_KlawdyIamRole=arn:aws:iam::076610420881:user/klawdy&param_KlawdyRoleType=READANDWRITE", '_blank');
    setOnBoardingWindow(onboardingWindow)
    setIsOnboardingWindowOpen(true)
    console.log("onboardingWindow in writing mode", onboardingWindow)
    socket.emit("message", "contenu du message")
  }

  const verify_aws_connexion = () => {
    api.get("/verify_aws_connexion", {
      withCredentials: true,
      headers: {
        "csrf-token": csrf.token
      }
    }).then((response) => {
      if (response.status == 200) {
        setIsAWSConnected(true)
        console.log(response.data)
        setAWSAccountRole(response.data.role)
      }
      else {
        setIsAWSConnected(false)
        console.log(response.data)

      }
    })
  }

  const change_arn_and_role = (arn, role) => {
    api.post("/change_arn_and_role", {
      "role_arn": arn,
      "role_type": role
    }, {
      withCredentials: true,
      headers: {
        "csrf-token": csrf.token
      }
    }).then((response) => {
      if (response.status == 200) {
        setTimeout(() => {
          navigate("/admin/dashboards/default")
        }, 1500);
      }
    })
  }
  const countryOptions = (selectedCountry) => {
    return countries.map((country, index) => {
      if (country == selectedCountry)
        return (
          <option value={country} key={index} selected>{country}</option>
        )
      else
        return (
          <option value={country} key={index} >{country}</option>
        )

    })
  };

  function onSubmit(values) {
    console.log("compnay_values", values)
    api.post("/add_company", {
      "email": values.email,
      "company_name": values.company_name,
      "website": values.website,
      "country": values.country,
      "city": values.city,
      "postalCode": values.postalCode,
    },
      {
        headers: {
          "csrf-token": csrf.token,
        },
        withCredentials: true

      })
      .then((response, resolve) => {
        if (response.status == 200) {
          dispatch(checkCompany(true))
          console.log("company added", response)
          navigate("/admin/onboarding")
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }

  // function checkCompany(values) {
  //   api.post("/has_comany", {
  //     "email": values.email,
  //     "company_name": values.company_name,
  //     "website": values.website,
  //     "country": values.country,
  //     "city": values.city,
  //     "postalCode": values.postalCode,
  //   },
  //     {
  //       headers: {
  //         "csrf-token": csrf.token,
  //       },
  //       withCredentials: true

  //     })
  //     .then((response, resolve) => {
  //       if (response.status == 200)
  //         console.log("company added", response)
  //       // navigate("/")
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     })
  // }

  useEffect(() => {
    const setCompanyInfo = async (csrf) => {
      if (auth?.hasCompany) {
        if (auth?.company == null) {
          console.log("company yes diffrent from null")
          let company = await userService.get_company(csrf)
          setCompany(company)
          dispatch(updateCompany(company))
          dispatch(updateIsAuthLoading(false))

        }

      }
    }
    console.log("company ici", auth?.company)

    setCompanyInfo(csrf)
  }, [auth?.hasCompany, auth?.company])


  useEffect(() => {


    console.log("environment variable in onboarding", environment)
    csrf.load_hashed_csrf_token()
    csrf.verify_token()
    console.log("csrf token", csrf.token)
    initUser(csrf)
    verify_aws_connexion(csrf)
    test_socket()

    //disconnects when components unmounts
    console.log("onboardingWindow in useEffect", onboardingWindow)

    socket.on('connect', function () {
      console.log('Connected to server');
      socket.once('disconnect', function () {
        console.log('Disconnected from server');
      });
      socket.emit("join", { "room": "klawdy" })

    });


    socket.on('test_socket', function (data) {
      console.log("test socket message reçu")
      console.log("test socket msg: " + data)
    })
    // socket.on('message', function (data) {
    //   console.log("message: ", data)
    // })

    // if (auth.hasCompany) {

    // }
    console.log("countries", countries)

  }, [])

  useEffect(() => {
    socket.on('message', function (data) {
      console.log("message: ", data)
    })
    console.log("isOnboardingWindowOpen  in second useEffect", isOnboardingWindowOpen)
    console.log("onboardingWindow in second useEffect", onboardingWindow)
    console.log("roleArn in second useEffect", roleARN)
    console.log("roleType in second useEffect", roleType)
    if (isOnboardingWindowOpen == false && onboardingWindow != null && onboardingWindow != undefined) {
      if (roleARN != "" && roleType != "") {
        change_arn_and_role(roleARN, roleType)
        onboardingWindow.close()
      }
    }
  }, [isOnboardingWindowOpen, onboardingWindow, roleARN, roleType])

  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      pt={{ sm: '125px', lg: '75px' }}
      position="relative"
    >
      <LoadingOverlay
        active={isActive}
        spinner={<BeatLoader color="white" />}
        text='Connecting AWS account...'
        styles={{
          wrapper: {
            width: '100%',
            height: '80%',
            // overflow: active ? 'hidden' : 'scroll'
          }
        }}
      >
        <Box
          h="45vh"
          bgGradient="linear(to-b, brand.400, brand.600)"
          position="absolute"
          w="100%"
          borderRadius="20px"
        />

        <Tabs
          variant="unstyled"
          mt={{ base: '60px', md: '165px' }}
          zIndex="0"
          display="flex"
          flexDirection="column"
        >
          <TabList
            display="flex"
            alignItems="center"
            alignSelf="center"
            justifySelf="center"
          >
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={productTab}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
              onClick={() =>
                setActiveBullets({
                  product: true,
                  media: false,
                  pricing: false,
                })
              }
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: '120px', md: '250px', lg: '300px' },
                  height: '3px',
                  bg: activeBullets.media ? 'white' : 'brand.400',
                  left: { sm: '12px', md: '40px' },
                  top: {
                    sm: activeBullets.product ? '6px' : '4px',
                    md: null,
                  },
                  position: 'absolute',
                  bottom: activeBullets.product ? '40px' : '38px',

                  transition: 'all .3s ease',
                }}
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.product ? 'white' : 'brand.400'}
                  bgGradient={auth.awsRole == "READONLY" || auth.awsRole == "READANDWRITE" ? "linear(to-b, brand.50, brand.50)" : "linear(to-b, brand.400, brand.600)"}
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.product ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.product ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  Read Permissions
                </Text>
              </Flex>
            </Tab>
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={mediaTab}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
              onClick={() =>
                setActiveBullets({
                  product: true,
                  media: true,
                  pricing: false,
                })
              }
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: '120px', md: '250px', lg: '300px' },
                  height: '3px',
                  bg: activeBullets.pricing ? 'white' : 'brand.400',
                  left: { sm: '12px', md: '28px' },
                  top: '6px',
                  position: 'absolute',
                  bottom: activeBullets.media ? '40px' : '38px',

                  transition: 'all .3s ease',
                }}
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.media ? 'white' : 'brand.400'}
                  bgGradient={auth.awsRole == "READANDWRITE" ? "linear(to-b, brand.50, brand.50)" : "linear(to-b, brand.400, brand.600)"}
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.media ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.media ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  Read & Write Permissions
                </Text>
              </Flex>
            </Tab>
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={pricingTab}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
              onClick={() =>
                setActiveBullets({
                  product: true,
                  media: true,
                  pricing: true,
                })
              }
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.pricing ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.pricing ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.pricing ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  Company
                </Text>
              </Flex>
            </Tab>
          </TabList>
          <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                  Read Permissions
                </Text>
                <Flex direction="column" justifyContent="center" alignItems="center" color="white" h="100%" w="100%">
                  <Lottie options={defaultOptions} height={200} width={200} />
                  {/* <Text color="black" fontSize={{ sm: 'xl', lg: 'lg', xl: 'xl' }} fontWeight="bold">
                  AWS
                </Text> */}
                  <Box textAlign="center" px="20">
                    {!(auth.awsRole == "READONLY" || auth.awsRole == "READANDWRITE") ? (
                      <Text color="black" fontSize="md">To see your estimated savings, we need <strong> READ-ONLY </strong> IAM permissions  on your management account.
                        Please see <a href="#" ><u>here</u> for more</a> </Text>
                    ) : (
                      <Text color="black" fontSize="md">You already added an AWS account with <strong> READ-ONLY </strong> IAM permissions.
                      </Text>
                    )}

                  </Box>
                </Flex>
                <Flex justify="space-between" mt="24px" >
                  <Button
                    variant="light"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={() => productTab.current.click()}
                  >
                    Prev
                  </Button>
                  <Button
                    variant="darkBrand"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={() => onBoarding()}
                    isDisabled={auth.awsRole == "READONLY" || auth.awsRole == "READANDWRITE" ? true : false}
                  >
                    Connect
                  </Button>
                </Flex>
              </Card>
            </TabPanel>
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                  Read & Write Permissions
                </Text>
                <Flex direction="column" justifyContent="center" alignItems="center" color="white" h="100%" w="100%">
                  <Lottie options={defaultOptions2} height={200} width={200} />
                  {/* <Text color="black" fontSize={{ sm: 'xl', lg: 'lg', xl: 'xl' }} fontWeight="bold">
                  AWS
                </Text> */}
                  <Box textAlign="center" px="20">
                    {!(auth.awsRole == "READANDWRITE") ? (
                      <Text color="black" fontSize="md">To achieve those savings on your behalf, Klawdy requires <strong> READ & WRITE </strong>permissions listed <a href=""><u>here</u></a> as previously we took
                        READ-Only permissions. Learn more <a href=""><u>here</u></a>
                      </Text>
                    ) : (
                      <Text color="black" fontSize="md">You already added an AWS account with <strong> READ & WRITE </strong>permissions
                      </Text>
                    )
                    }

                  </Box>
                </Flex>
                <Flex justify="space-between" mt="24px">
                  <Button
                    variant="light"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={() => productTab.current.click()}

                  >
                    Prev
                  </Button>
                  <Button
                    variant="darkBrand"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    onClick={() => onBoarding2()}
                    isDisabled={auth.awsRole == "READANDWRITE" ? true : false}

                  >
                    Connect
                  </Button>
                </Flex>
              </Card>
            </TabPanel>
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text color={textColor} fontSize="2xl" fontWeight="700" mb="20px">
                  Company
                </Text>
                <Flex direction="column" w="100%">
                  <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <FormControl isInvalid={errors.name}>

                  <Stack direction="column" spacing="20px">
                    <SimpleGrid
                      columns={{ base: 1, md: 3 }}
                      gap={{ base: '0px', md: '20px' }}
                    >
                      <InputField
                            id="company_name"
                            placeholder="Company_name"
                            label="Company legal Name"
                            register={register('company_name', {
                              required: 'This is required',
                            })}
                            errors={errors}
                            isDisabled={auth.hasCompany}
                            defaultValue={auth.hasCompany ? auth.company?.company_name : ""}

                          />

                          <InputField
                            id="website"
                            placeholder="company_name.com"
                            label="Website"
                            register={register('website', {
                              required: 'This is required',
                            })}
                            errors={errors}
                            isDisabled={auth.hasCompany}
                            defaultValue={auth.hasCompany ? auth?.company?.website : ""}


                          />
                          <InputField
                            id="email"
                            placeholder="prefix@company.com"
                            label="Company email address"
                            register={register('email', {
                              required: 'This is required',
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "invalid email address"
                              }
                            })
                            }
                            errors={errors}
                            isDisabled={auth.hasCompany}
                            defaultValue={auth.hasCompany ? auth?.company?.email : ""}



                      />
                      <Flex direction="column" mb="34px">
                        <FormLabel
                          ms="10px"
                              htmlFor="country"
                          fontSize="sm"
                          color={textColor}
                          fontWeight="bold"
                              _hover={{ cursor: 'pointer' }}

                        >
                              Country
                        </FormLabel>

                        <Select
                              fontSize="sm"
                              id="country"
                              variant="main"
                              h="44px"
                              maxH="44px"
                              color={textColor}
                              defaultValue={auth?.company?.country}
                              isDisabled={auth.hasCompany}
                              style={{ color: "black" }}>
                              {!(auth?.isAuthLoading) ? (
                                countryOptions(auth?.company?.country)
                              ) : (<></>)}
                            </Select>

                          </Flex>
                          <InputField
                            id="city"
                            placeholder="Paris"
                            label="City"
                            register={register('city', {
                              required: 'This is required',
                            })}
                            errors={errors}
                            isDisabled={auth.hasCompany}
                            defaultValue={auth.hasCompany ? auth?.company?.city : ""}


                          />
                          <InputField
                            id="postalCode"
                            placeholder="93312"
                            label="Zip/Postal Code"
                            register={register('postalCode', {
                              required: 'This is required',
                              minLength: { value: 3, message: 'Minimum length should be 3' },
                              pattern: {
                                value: /^[0-9]{3,10}$/i,
                                message: "invalid postal Code"
                              },
                            })}
                            isDisabled={auth.hasCompany}
                            defaultValue={auth.hasCompany ? auth?.company?.postalCode : ""}
                            errors={errors}
                          />
                    </SimpleGrid>

                        {/* <TagsField /> */}
                  </Stack>
                  <Flex justify="space-between" mt="24px">
                    <Button
                      variant="light"
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                      onClick={() => mediaTab.current.click()}
                    >
                      Prev
                    </Button>
                    <Button
                      variant="darkBrand"
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                          isLoading={isSubmitting}
                          type="submit"
                          isDisabled={auth.hasCompany}

                    >
                      Submit
                    </Button>
                        <FormErrorMessage>
                          {errors.name && errors.name.message}
                        </FormErrorMessage>
                      </Flex>
                    </FormControl >
                  </form>
                </Flex>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>

      </LoadingOverlay>

    </Flex>
  );
}

export default NewProduct;