// Chakra imports
import {
  Portal,
  Box,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin';
// Layout components
import ProfileSettings from 'views/admin/main/profile/settings';
import Navbar from 'components/navbar/NavbarAdmin';
import Sidebar from 'components/sidebar/Sidebar';
import { SidebarContext } from 'contexts/SidebarContext';
import { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import routes from 'routes';
import UserService from 'UserService';
import csrf from 'csrf';
import api from "api"
import { useDispatch, useSelector } from 'react-redux';
import { loginAction, logoutAction, checkCompany, checkAWSConnexion, updateUser, updateIsAuthLoading, updateIsLoggedInWithGoogle, updateCompany, updateCookieSetIntervalId, updateAvatar, updateIsAuthenticated } from 'features/user/userSlice'
import { checkUserConnexion, hexStringToBinary } from 'functions/functions';
import { ToastContainer, toast } from 'react-toastify';





// Custom Chakra theme
export default function Dashboard(props: { [x: string]: any }) {
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [mini, setMini] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [total, setTotal] = useState("3456")
  const user = useSelector((state: any) => state.auth.user)
  const auth = useSelector((state: any) => state.auth)
  const dispatch = useDispatch()


  // functions for changing the states from components
  const userService = new UserService()



  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps';
  };
  const getActiveRoute = (routes: RoutesType[]): string => {
    // let activeRoute = 'Default Brand Text';
    let activeRoute = 'Connexion'

    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((route: RoutesType, key: number) => {
      if (route.layout === '/admin') {
        return (
          <Route path={`${route.path}`} element={route.component} key={key} />
        );
      }
      if (route.collapse) {
        return getRoutes(route.items);
      } else {
        return null;
      }
    });
  };
  console.log(mini);
  document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();
  const bg = useColorModeValue('background.100', 'background.900');



  // checking constanly  if user is connected 
  useEffect(() => {

    async function get_user(csrf: any) {
      if (user == null) {
        let tmp_user = await userService.getUser(csrf).catch((error) => console.log(error))
        console.log("user in admin parent", tmp_user)
        if (Object.keys(tmp_user).length != 0) {
          dispatch(updateUser({ ...tmp_user }))
          // dispatch(updateIsAuthenticated(true))
        }
        // else {
        //   window.location.href = window.location.origin + "/auth/sign-in/default"
        // }
      }
    }
    async function get_csrf_token() {
      try {
        let response = await api.get("/get_csrf_token")
        csrf.load_hashed_csrf_token()
        csrf.verify_token()
      } catch (error) {
        console.log(error)
      }
    }
    async function checkIsLoggedIndWithGoogle(csrf: any) {
      await userService.hasLoggedInWithGoogle(csrf).then(data => {
        dispatch(updateIsLoggedInWithGoogle(data))

      }).catch((error) => console.log(error))
    }

    async function get_avatar(csrf: any) {
      await userService.getAvatar(csrf).then(data => {
        if (data["image"] != "") {
          const binaryData = hexStringToBinary(data["image"]);
          const blob = new Blob([binaryData], { type: 'image/jpeg' });
          let avatarUrl = URL.createObjectURL(blob)
          dispatch(updateAvatar(avatarUrl))
        }
      }).catch((error) => console.log(error))
    }



    async function checkAWSAndCompany(csrf: any) {
      await userService.hasCompany(csrf).then(data => {
        dispatch(checkCompany(data))
      }).catch((error) => console.log(error))
      await userService.hasAwsAccount(csrf).then(data => {
        dispatch(checkAWSConnexion(data))
        dispatch(updateIsAuthLoading(false))
      }).catch((error) => console.log(error))
    }


    // get_csrf_token().then(() => {
    // })
    csrf.load_hashed_csrf_token()
    csrf.verify_token()
    get_user(csrf)
    get_avatar(csrf)
    checkAWSAndCompany(csrf)
    checkIsLoggedIndWithGoogle(csrf)
    if (auth.isAuthenticated && auth.cookieSetIntervalId == null) {
      let intervalId = checkUserConnexion(csrf, api)
      dispatch(updateCookieSetIntervalId(intervalId))
    }


  }, []);

  return (
    <Box bg={bg} h="100vh" w="100vw">
      <ToastContainer />
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar
          hovered={hovered}
          setHovered={setHovered}
          mini={mini}
          routes={routes}
          display="none"
          {...rest}
        />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={
            mini === false
              ? { base: '100%', xl: 'calc( 100% - 290px )' }
              : mini === true && hovered === true
              ? { base: '100%', xl: 'calc( 100% - 290px )' }
              : { base: '100%', xl: 'calc( 100% - 120px )' }
          }
          maxWidth={
            mini === false
              ? { base: '100%', xl: 'calc( 100% - 290px )' }
              : mini === true && hovered === true
              ? { base: '100%', xl: 'calc( 100% - 290px )' }
              : { base: '100%', xl: 'calc( 100% - 120px )' }
          }
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Navbar
                hovered={hovered}
                setMini={setMini}
                mini={mini}
                onOpen={onOpen}
                logoText={'Horizon UI Dashboard PRO'}
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                theme={props.theme}
                setTheme={props.setTheme}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          {getRoute() ? (
            <Box
              mx="auto"
              p={{ base: '20px', md: '30px' }}
              pe="20px"
              minH="100vh"
              pt="50px"
            >

              <Routes>
                {getRoutes(routes)}
                <Route
                  path="/main/profile/settings"
                  element={
                    <ProfileSettings />
                  }
                />
                <Route
                  path="/"
                  element={<Navigate to="/admin/dashboards/default" replace />}
                />
              </Routes>
            </Box>
          ) : null}
          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
