// Chakra imports
import { Box, Flex, HStack, Heading, Icon, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import Footer from 'components/footer/FooterAuthDefault';
import FixedPlugin from 'components/fixedPlugin/FixedPlugin';
// Custom components
import { NavLink } from 'react-router-dom';
// Assets
import { FaAws, FaChevronLeft } from 'react-icons/fa';
import { MdAccountBalanceWallet, MdOutlinePriceChange, MdOutlineSecurity } from 'react-icons/md';
import { Image } from '@chakra-ui/react'

function AuthIllustration(props: {
  children: JSX.Element;
  illustrationBackground: string;
}) {
  const { children, illustrationBackground } = props;
  // Chakra color mode
  return (
    <Flex position="relative" h="max-content">
      <Flex
        h={{
          sm: 'initial',
          md: 'unset',
          lg: '100vh',
          xl: '97vh',
        }}
        w="100%"
        maxW={{ md: '66%', lg: '1313px' }}
        mx="auto"
        pt={{ sm: '50px', md: '0px' }}
        px={{ lg: '30px', xl: '0px' }}
        ps={{ xl: '70px' }}
        justifyContent="start"
        direction="column"
      >
        <NavLink
          to="/admin"
          style={() => ({
            width: 'fit-content',
            marginTop: '40px',
          })}
        >
          <Flex
            align="center"
            ps={{ base: '25px', lg: '0px' }}
            pt={{ lg: '0px', xl: '0px' }}
            w="fit-content"
          >
            {/* <Icon
              as={FaChevronLeft}
              me="12px"
              h="13px"
              w="8px"
              color="secondaryGray.600"
            />
            <Text ms="0px" fontSize="sm" color="secondaryGray.600">
              Back to Dashboard
            </Text> */}
          </Flex>
        </NavLink>
        {children}
        <Box
          display={{ base: 'none', md: 'block' }}
          h="100%"
          minH="100vh"
          w={{ lg: '50vw', '2xl': '44vw' }}
          position="absolute"
          right="0px"
          bgGradient="linear(to-b, brand.400, brand.600)"
          borderBottomLeftRadius={{ lg: '120px', xl: '200px' }}
        >
          <Flex
            // bg={`url(${illustrationBackground})`}
            justify="start"
            align="start"
            w="60%"
            h="60%"
            bgSize="contain"
            bgPosition="center"
            bgRepeat="no-repeat"
            position="absolute"
            left="150px"
            top="20px"
            direction="column"
          >
            <Image src={illustrationBackground} alt='Dan Abramov' w="30%" mb="20px" ml={{
              base: "85px",
              lg: "95px",
              xl: "110px",

            }} />
            <Heading color={"white"} fontSize="36px" mr="40px">
              AWS Costs Made Easy
            </Heading>
            <Flex align='center' mt="40px" justify={"start"} me={{ base: '8px', lg: '20px' }} >
              <Icon mt='2px' h='20px' w='20px' color='white' as={MdOutlineSecurity} me='8px' />
              <Heading color={"white"} fontSize="22px" >
                Security
              </Heading>
            </Flex>
            <Text color={"white"} mt={"10px"} mr={"30px"}>
              Klawdy employs robust encryption to protect user's data
            </Text>

            <Flex align='center' mt="40px" me={{ base: '8px', lg: '20px' }}  >
              <Icon mt='2px' h='20px' w='20px' color='white' as={MdAccountBalanceWallet} me='8px' />
              <Heading color={"white"} fontSize="22px"  >
                Seamless AWS Onboarding
              </Heading>
            </Flex>
            <Text color={"white"} mt={"10px"}>
              Klawdy connects to AWS in just a few clicks, enabling quick cost monitoring and efficient budget management.            </Text>
            <Flex align='center' mt="40px" me={{ base: '8px', lg: '20px' }}   >
              <Icon mt='2px' h='20px' w='20px' color='white' as={MdOutlinePriceChange} me='8px' />
              <Heading color={"white"} fontSize="22px" >
                Comprehensive Cost Tracking
              </Heading>
            </Flex>
            <Text color={"white"} mt={"10px"}>
              Klawdy gives a clear view of AWS costs, usage, and trends to manage finances and prevent overspending    </Text>
            <Text color={"white"} mt="125px" fontWeight="100" fontSize="14px">
              Have a question?
              <a href="https://3deurqxoc3r.typeform.com/to/ndPaYuvY">
                <Text
                  color={"white"}
                  as="span"
                  ms="5px"
                  fontWeight="700"
                >
                  <u>
                    Talk to our team
                  </u>
                </Text>
              </a>
            </Text>
          </Flex>
        </Box>
        {/* <Footer /> */}
      </Flex>
    </Flex>
  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
