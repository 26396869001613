// Chakra imports
import {
  Avatar,
  Flex,
  Input,
  Select,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import Upload from '../../overview/components/Upload';
import { useRef } from 'react';
import api from 'api'
import { toast } from 'react-toastify';
import csrf from "csrf"
import { updateAvatar } from 'features/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function Settings(props: {
  name: string;
  avatar: string;
  banner: string;
}) {
  // eslint-disable-next-line
  const { name, avatar, banner } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  const auth = useSelector((state: any) => state.auth)
  const dispatch = useDispatch()

  const fileInputRef = useRef(null);

  const UploadAvatar = () => {
    console.log("avatar clicked")
    fileInputRef.current.click()

  }
  const handleFileChange = async (e: any) => {
    const file = e.target.files[0];
    csrf.load_hashed_csrf_token()
    csrf.verify_token()
    try {
      const formData = new FormData();
      formData.append('file', file);
      let response = await api.post("/upload_avatar", formData, {
        headers: {
          "csrf-token": csrf.token,
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true
      }
      )
      if (response.status == 200) {
        toast.success("Avatar uploaded successfully", {
          position: "top-right",
          theme: "colored",

        });
        let avatarUrl = URL.createObjectURL(file)
        dispatch(updateAvatar(avatarUrl))
      }
      else
        toast.error("Error uploading avatar image", {
          position: "top-right",
          theme: "colored",

        });


    } catch (error) {
      console.log(error)
    }


    console.log("file", file)
  };

  return (
    <Card mb="20px" alignItems="center">
      {/* <Image src={banner} borderRadius="16px" /> */}
      <Flex
        w="100%"
        bgGradient="linear(to-b, brand.400, brand.600)"
        minH="127px"
        borderRadius="16px"
      ></Flex>
      <Input type="file" style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileChange} />
      <button onClick={UploadAvatar}>
        {auth?.avatar == null ? (
          <Avatar mx="auto" src={avatar} h="87px" w="87px" mt="-43px" mb="15px" />) :
          (<Avatar mx="auto" src={auth?.avatar} h="87px" w="87px" mt="-43px" mb="15px" />)}
      </button>
      <Text fontSize="2xl" textColor={textColorPrimary} fontWeight="700">
        {name}
      </Text>
      <Flex align="center" mx="auto" px="15px">
        <Text
          me="4px"
          color={textColorSecondary}
          fontSize="sm"
          fontWeight="400"
          lineHeight="100%"
        >
          Account type: 
        </Text>
        <Text
          textColor={textColorPrimary}
          color={textColorPrimary}
          fontWeight='bold'
          ml={"10px"}
        >
          Member
        </Text>
        {/* <Select
          id="user_type"
          w="unset"
          variant="transparent"
          display="flex"
          textColor={textColorPrimary}
          color={textColorPrimary}
          alignItems="center"
          defaultValue="Administrator"
        >
          <option value="Administrator">Administrator</option>
          <option value="Member">Member</option>
        </Select> */}
      </Flex>
    </Card>
  );
}
