import axios from 'axios';
import csrf from "csrf"
import { convertToObject } from 'typescript';
import cookieService from "universal-cookie"


let environment = process.env.REACT_APP_ENVIRONMENT
console.log("environment", environment)
let base_url = environment == 'development' ? "http://localhost:5000" : "https://api.klawdy.tech"
const instance = axios.create({
    baseURL: base_url,
    _retry: false,
    withCredentials: true,
});


instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        var originalConfig = err.config;

        if (originalConfig.url !== "/login" && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                console.log("originalConfig", originalConfig)
                console.log("originalConfig retry", originalConfig._retry)
                console.log("originalConfig retry boolean", originalConfig._retry == false)
                originalConfig._retry = true;

                if (err.response.data === "Refresh Token expired" || err.response.data === "Session expired" || err.response.data === "User not matching" || err.response.data === "CSRF-Token not present" || err.response.data === "User not matching" || err.response.data === "Wrong csrf-token"
                    || err.response.data["msg"] == 'Missing cookie "access_token"') {
                    // sessionStorage.setItem("previous_url", window.location.href)
                    // if (err.response.data["msg"] != undefined || err.response.data["msg"] != null)
                    //     // alert(err.response.data["msg"])
                    // else alert(err.response.data)
                    const rs = await instance.get("/logout", {
                    }, { withCredentials: true, _retry: false });
                    if (rs.status == 200)
                        window.location.href = window.location.origin + "/auth/sign-in/default"
                        console.log("redirection")
                }

                else {
                    try {
                        const rs = await instance.post("/token/refresh", {
                        }, { withCredentials: true, _retry: false });
                        return instance(originalConfig);
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                }

            }
        }

        return Promise.reject(err);
    }
);

export default instance;