import './assets/css/App.css';
import { Routes, Route, Navigate, useNavigate, } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import ProfileSettings from 'views/admin/main/profile/settings';

import {
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useState } from 'react';
import ConnexionServer from 'views/admin/dashboards/default/ConnectionServer';
import Loader from 'views/admin/dashboards/default/Loader';
import SignInDefault from 'views/auth/signIn/SignInDefault';
import SignUpDefault from 'views/auth/signUp/SignUpDefault';
import { store } from 'store'
import { Provider } from 'react-redux'
import ProtectedRoute from 'components/protectedRoute/ProtectedRoute';


// Chakra imports

export default function Main() {
  // eslint-disable-next-line
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  return (
    <Provider store={store}>
    <ChakraProvider theme={currentTheme}>
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route
          path="admin/*"
            element={
              <ProtectedRoute>
            <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
              </ProtectedRoute>

          }
          >
          </Route>
        <Route
          path="rtl/*"
          element={
            <RTLLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
        />
        <Route
          path="/"
          element={
            <Navigate to="/auth/sign-in/default" replace={true} />
          }
        />
        <Route
          path="/connexion"
          element={
            <ConnexionServer />
          }
        />
        <Route
          path="/auth/sign-in/default"
          element={
            <SignInDefault />
          }
        />
        <Route
          path="/auth/sign-up/default"
          element={
            <SignUpDefault />
          }
        />

        <Route
          path="/loader"
          element={
            <Loader />
          }
        />
        {/* <Route path="/" element={<Navigate to="/admin" replace />} /> */}
      </Routes>
      </ChakraProvider>
    </Provider>

  );
}
