/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Flex, Grid } from '@chakra-ui/react';
// import Card from 'components/card/Card';
import React, { useState } from 'react';
import Banner from 'views/admin/main/ecommerce/overviewProduct/components/Banner';
import SearchTableOverview from 'views/admin/main/ecommerce/overviewProduct/components/SearchTableOverview';
import tableDataOverview from 'views/admin/main/ecommerce/overviewProduct/variable/tableDataOverview';
import Newsletter from 'views/admin/main/account/settings/components/Newsletter';
import Earn from 'views/admin/main/ecommerce/referrals/components/Earn';
import Invite from 'views/admin/main/ecommerce/referrals/components/Invite';
import InviteSecond from 'views/admin/main/ecommerce/referrals/components/Invite2';

export default function ProductOverview(props: { page: string }) {
	const { page } = props
	const [isChecked, setIsChecked] = useState(false)
	const [isAnomalyChecked, setIsAnomalyChecked] = useState(false)


	return (
		<Flex direction='column' pt={{ sm: '125px', lg: '75px' }} mb='20px'>
			<Banner page={page} />
			{page != "savings" ? (
				<Flex direction={{
					base: "column",
					md: "row"
				}} mt='60px' gap="8" >
					<Newsletter changeIsChecked={setIsChecked} isChecked={isChecked} isAnomalyChecked={isAnomalyChecked} changeIsAnomalyChecked={setIsAnomalyChecked} />
					<Box w={{
						base: "100%",
						md: "62%"
					}}>
						{isChecked ? (
							<Invite w="100%"
								referralCode="simmmple.com/referral=9421738401"
								fbLink="#"
								twtLink="#"
								gridArea={{ base: '2 / 1 / 3 / 3', '2xl': '1 / 2 / 2 / 3' }}
							/>) :
							isAnomalyChecked ?
								(<InviteSecond w="100%"
									referralCode="simmmple.com/referral=9421738401"
									fbLink="#"
									twtLink="#"
									gridArea={{ base: '2 / 1 / 3 / 3', '2xl': '1 / 2 / 2 / 3' }}
								/>) : (
									<Earn w="100%"
										me="20px"
										gridArea={{ base: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}
									/>)
						}
					</Box>

				</Flex>
			) : (<></>)}


		</Flex>
	);
}
