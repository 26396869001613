// Chakra imports
import { Button, Flex, FormControl, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import api from "api"
import csrf from "csrf"

export default function Settings() {
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.600';

	const {
		handleSubmit,
		register,
		formState: { errors, isSubmitting },
	} = useForm()

	const navigate = useNavigate()


	function onSubmit(values: any) {
		console.log("company_values", values)
		api.post("/add_company", {
			"email": values.email,
			"company_name": values.company_name,
			"website": values.website,
			"country": values.country,
			"city": values.city,
			"postalCode": values.postalCode,
		},
			{
				headers: {
					"csrf-token": csrf.token,
				},
				withCredentials: true

			})
			.then((response: any) => {
				if (response.status == 200) {
					alert("Account info updated")
					navigate("/admin/main/profile/settings")
				}
			})
			.catch((error) => {
				console.log(error);
			})

	}
	return (
		<form onSubmit={handleSubmit(onSubmit)} noValidate>
		<FormControl>
			<Card>
				<Flex direction='column' mb='40px' ms='10px'>
					<Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
						Change password
					</Text>
					<Text fontSize='md' color={textColorSecondary}>
						Here you can set your new password
					</Text>
				</Flex>
				<FormControl>
					<Flex flexDirection='column'>
							<InputField mb='25px' id='old' label='Old Password' placeholder='@john123' register={register('company_name', { required: 'This is required', })} errors={errors} />
							<InputField mb='25px' id='new' label='New Password' placeholder='@john123' register={register('company_name', { required: 'This is required', })} errors={errors} />
							<InputField mb='25px' id='confirm' label='New Password Confirmation' placeholder='@john123'
								{...register('password', {
									required: 'This is required',
									minLength: { value: 8, message: 'Minimum length should be 8' },
									pattern: {
										value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/,
										message: 'Password must contain at least one uppercase letter, one lowercase letter, and one number',
									},

								})} errors={errors} />
					</Flex>
				</FormControl>
				<Button variant='brand' minW='183px' fontSize='sm' fontWeight='500' ms='auto'>
					Change Password
				</Button>
			</Card>
			</FormControl>
		</form>
	);
}
