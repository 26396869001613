// chakra imports
import {
  Avatar,
  Box,
  Flex,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
//   Custom components
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';
import SidebarCard from 'components/sidebar/components/SidebarCard';
import avatar4 from 'assets/img/avatars/avatar4.png';
import avatar from 'assets/img/avatars/avatar.png';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Cookies from "universal-cookie";
import { useSelector, useStore } from 'react-redux';



// FUNCTIONS

function SidebarContent(props: {
  routes: RoutesType[];
  hovered?: boolean;
  mini?: boolean;
}) {
  const { routes, mini, hovered } = props;
  const textColor = useColorModeValue('navy.700', 'white');
  const [url, setUrl] = useState("https://dolphin-app-h9ivo.ondigitalocean.app/")
  const [user, setUser] = useState({ "firstName": (localStorage.getItem("firstName") || "None"), "lastName": (localStorage.getItem("lastName") || "None") })
  const store = useStore()
  console.log("store state", store.getState())
  const auth = useSelector((state: any) => state.auth)
  console.log("auth", auth)

  useEffect(() => {

  }, []);

  // SIDEBAR
  return (
    <Flex direction="column" height="100%" pt="25px" borderRadius="30px">
      <Brand mini={mini} hovered={hovered} />
      <Stack direction="column" mb="auto" mt="8px">
        <Box
          ps={
            mini === false
              ? '20px'
              : mini === true && hovered === true
              ? '20px'
              : '16px'
          }
          pe={{ md: '16px', '2xl': '1px' }}
          ms={mini && hovered === false ? '-16px' : 'unset'}
        >
          <Links mini={mini} hovered={hovered} routes={routes} />
        </Box>
      </Stack>

      <Box
        ps="20px"
        pe={{ md: '16px', '2xl': '0px' }}
        mt="60px"
        borderRadius="30px"
      >
        <SidebarCard mini={mini} hovered={hovered} />
      </Box>
      <Flex mt="75px" mb="56px" justifyContent="center" alignItems="center">
        {auth?.avatar == null ? (<Avatar
          h="48px"
          w="48px"
          src={avatar}
          me={
            mini === false
              ? '20px'
              : mini === true && hovered === true
                ? '20px'
                : '0px'
          }
        />) : (<Avatar
          h="48px"
          w="48px"
          src={auth?.avatar}
          me={
            mini === false
              ? '20px'
              : mini === true && hovered === true
                ? '20px'
                : '0px'
          }
        />)}

        <Box
          display={
            mini === false
              ? 'block'
              : mini === true && hovered === true
              ? 'block'
              : 'none'
          }
        >
          <Text color={textColor} fontSize="md" fontWeight="700">
            {auth.user?.firstName} 
          </Text>
          <Text color="secondaryGray.600" fontSize="sm" fontWeight="400">
            {/* Developper */}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SidebarContent;
