// Chakra imports
import { Button, Flex, FormControl, FormLabel, Select, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import api from "api"
import csrf from "csrf"
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserService from 'UserService';
import { checkCompany, updateCompany } from 'features/user/userSlice';
import countries from "variables/countries"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function Settings() {
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'secondaryGray.600';
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const auth = useSelector((state: any) => state.auth)
	const dispatch = useDispatch()
	const userService = new UserService()
	const [country, setCountry] = useState("")

	const {
		handleSubmit,
		register,
		formState: { errors, isSubmitting },
	} = useForm()

	const navigate = useNavigate()

	const handleCountryChange = (e: any) => {
		console.log("selected country", e.target.value)
		setCountry(e.target.value)
	}

	const countryOptions = (selectedCountry: string) => {
		return countries.map((country, index) => {
			if (country == selectedCountry)
				return (
					<option value={country} key={index} selected>{country}</option>
				)
			else
				return (
					<option value={country} key={index} >{country}</option>
				)

		})
	};

	function onSubmit(values: any) {
		console.log("company_values", values)
		let email = values?.email || auth.company?.email
		let company_name = values?.company_name || auth?.company?.company_name
		let website = values?.website || auth?.company?.website
		let tmp_country = values?.country || country
		let city = values?.city || auth?.company?.city
		let postalCode = values?.postalCode || auth?.company?.postalCode
		api.post("/update_company", {
			"email": email,
			"company_name": company_name,
			"website": website,
			"country": tmp_country,
			"city": city,
			"postalCode": postalCode,
		},
			{
				headers: {
					"csrf-token": csrf.token,
				},
				withCredentials: true

			})
			.then((response: any) => {
				if (response.status == 200) {
					dispatch(updateCompany({
						"email": email,
						"company_name": company_name,
						"website": website,
						"country": tmp_country,
						"city": city,
						"postalCode": postalCode,
					}))
					toast.success("Company info updated sucessfully !", {
						position: "top-right",
						theme: "colored",

					});
					//add a dispatch here
				}
			})
			.catch((error) => {
				console.log(error);
			})

	}

	useEffect(() => {
		csrf.load_hashed_csrf_token()
		csrf.verify_token()
	}, [])

	useEffect(() => {
		const setCompanyInfo = async (csrf: any) => {
			if (auth?.hasCompany) {
				if (auth?.company == null) {
					console.log("company yes diffrent from null")
					let company = await userService.get_company(csrf)
					// setCompany(company)
					dispatch(updateCompany(company))
				}

			}
		}
		console.log("company ici dans profile settings", auth?.company)
		setCompanyInfo(csrf)
	}, [auth?.hasCompany, auth?.company?.country])

	return (
		<form onSubmit={handleSubmit(onSubmit)} noValidate>
		<FormControl>
				<Card mb='20px' pb='50px'>
				<Flex direction='column' mb='40px' ms='10px'>
					<Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
						Company Profile
					</Text>
					<Text fontSize='md' color={textColorSecondary}>
						Here you can set user company profile
					</Text>
				</Flex>
				<SimpleGrid columns={{ sm: 1, md: 2 }} spacing={{ base: '20px', xl: '20px' }}>
						<InputField mb='25px' id='email' label='Company Email' placeholder='doe@company.com'
							register={register('email', {
								required: auth.company?.email ? false : "This is required",
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: "invalid email address"
								}
							})} errors={errors} defaultValue={auth.company?.email} />
						<InputField mb='25px' me='30px' id='company_name' label='Company Name' placeholder='company_name' register={register('company_name', { required: auth.company?.company_name ? false : 'This is required', })}
							errors={errors} defaultValue={auth.company?.company_name} />
						<InputField mb='25px' id='website' label='Website' placeholder='company.com' register={register('website', { required: auth.company?.website ? false : 'This is required' })}
							errors={errors} defaultValue={auth.company?.website} />
						<Flex direction="column" mb="34px">
							<FormLabel
								ms="10px"
								htmlFor="country"
								fontSize="sm"
								color={textColor}
								fontWeight="bold"
								_hover={{ cursor: 'pointer' }}

							>
								Country
							</FormLabel>
							<Select
								fontSize="sm"
								id="country"
								variant="main"
								h="44px"
								maxH="44px"
								// defaultValue={auth.hasCompany ? company["country"] : "France"}
								defaultValue={auth?.company?.country}
								// value={auth.hasCompany ? company["country"] : ""}
								style={{ color: "black" }}
								name='country'
								onChange={handleCountryChange}
							>
								{(auth?.company?.country != null && auth?.company?.country != undefined) ? (
									countryOptions(auth?.company?.country)
									// <option>{auth?.company?.country}</option>
								) : (<></>)}
							</Select>
						</Flex>
						<InputField mb='25px' id='city' label='City' placeholder='Paris' register={register('city', { required: auth.company?.city ? false : 'This is required' })}
							errors={errors} defaultValue={auth.company?.city} />
						<InputField mb='25px' id='postalCode' label='Postal Code' placeholder='9347'
							register={register('postalCode', {
								required: auth.company?.postalCode ? false : 'This is required',
								minLength: { value: 3, message: 'Minimum length should be 3' },
								pattern: {
									value: /^[0-9]{3,10}$/i,
									message: "invalid postal Code"
								},
							})}
							errors={errors} defaultValue={auth.company?.postalCode} />

				</SimpleGrid>
					<Button type='submit' variant='brand' minW='183px' fontSize='sm' fontWeight='500' ms='auto'>
					Save changes
				</Button>
			</Card>
			</FormControl>
		</form>

	);
}
