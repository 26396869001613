import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import * as location from "./Cloud.json";
import * as success from "./Success.json";
import axios from "axios";
import DashboardsDefault from 'views/admin/dashboards/default';
import { useNavigate, useLocation } from "react-router-dom";
import api from "api"
import csrf from "csrf"
import { Box, Center, Flex, Text, useColorModeValue } from '@chakra-ui/react';



const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: location.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: success.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

function ConnectionServer() {
    const [data, setData] = useState([]);
    const [loading, setloading] = useState(undefined);
    const [completed, setcompleted] = useState(undefined);

    const [isConnected, setIsConnected] = useState(false)

    const [arn, setRrn] = useState(process.env.REACT_APP_ARN)
    const navigate = useNavigate();

    let logoColor = useColorModeValue('navy.700', 'white');
    const location = useLocation();
    const state = location.state;

    useEffect(() => {
        const redirection = () => {
            setTimeout(() => {
                setIsConnected(true)
                console.log("connexion établie");
                setloading(true);
                setTimeout(() => {
                    // if (state == null)
                        navigate("/admin/dashboards/default", { state: { modalIsOpen: false } })
                    // else
                    //     navigate(state.redirect, { state: { modalIsOpen: false } })

                    // setcompleted(true);
                }, 1000);
            }, 5000);
        }
        // const connexion = () => {
        //     api.post("/connexion", {
        //         "arn": arn
        //     }, {
        //         withCredentials: true,
        //           //     }).then((response, resolve) => {
        //             if (response.status == 200) {
        //                 setTimeout(() => {
        //                     setIsConnected(true)
        //                     console.log("connexion établie");
        //                     setloading(true);
        //                     setTimeout(() => {
        //                         navigate("admin/dashboards/default")
        //                         // setcompleted(true);
        //                     }, 1000);
        //                 }, 5000);

        //             }

        //             // Handle data
        //             // setTotal(getTotalCost(url))
        //             // getCostByService()

        //         })
        //         .catch((error) => {
        //             console.log(error);
        //         })
        // }
        csrf.load_hashed_csrf_token()
        csrf.verify_token()
        console.log("csrf token in connexion server", csrf.token)
        // connexion()
        redirection()
    }, []);


  

    return (
        <>
            {!completed ? (
                <Flex width={"100vw"} height={"100vh"} style={{ background: "#e9ecef" }} alignContent={"center"} justifyContent={"center"}>
                    <Center >

                    {!loading ? (
                            <Box  ><Lottie options={defaultOptions1} height={200} width={200} />

                            {/* <h1 className="text-center text-xl" >Loading... </h1> */}
                                <Text
                                fontSize={'30px'}
                                fontWeight="800"
                                color={logoColor}
                                marginBottom={"0px"}
                                marginLeft={"45px"}
                            >
                                KLAWDY
                            </Text>
                            </Box>
                        
                    ) : (
                                <Box>
                                <Lottie options={defaultOptions2} height={190} width={190} />
                                </Box>
                        )}
                    </Center>
                </Flex >
            ) : (
                <h1>Erreur dans le chargement</h1>
            )}
        </>
    );
}

export default ConnectionServer;
