/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

// Chakra imports
import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import { HSeparator } from 'components/separator/Separator';
import DefaultAuth from 'layouts/auth/variants/Default';
// Assets
import illustration from 'assets/img/auth/auth3.png';
import { FcGoogle } from 'react-icons/fc';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';

import { useForm } from 'react-hook-form'

import { jwtDecode } from "jwt-decode";

import axios from "axios";
import api from "api"
import { useNavigate } from "react-router-dom";
import { GoogleLogin, useGoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

import '../../../assets/css/App.css';


import csrf from "csrf"
import { useCookies } from 'react-cookie';
import cookieService from "universal-cookie"

import UserService from 'UserService';

import { loginAction, logoutAction, checkCompany, checkAWSConnexion, updateIsAuthLoading, updateIsLoggedInWithGoogle, updateCookieSetIntervalId, updateAvatar, updateIsAuthenticated } from 'features/user/userSlice'
import { useSelector, useDispatch } from 'react-redux'
import { checkUserConnexion, hexStringToBinary } from 'functions/functions';
import { ToastContainer, toast } from 'react-toastify';



function SignIn() {
    // Chakra color mode
    const textColor = useColorModeValue('navy.700', 'white');
    const textColorSecondary = 'gray.400';
    const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
    const textColorBrand = useColorModeValue('brand.500', 'white');
    const brandStars = useColorModeValue('brand.500', 'brand.400');
    const googleBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.200');
    const googleText = useColorModeValue('navy.700', 'white');
    const googleHover = useColorModeValue(
        { bg: 'gray.200' },
        { bg: 'whiteAlpha.300' },
    );
    const googleActive = useColorModeValue(
        { bg: 'secondaryGray.300' },
        { bg: 'whiteAlpha.200' },
    );
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);

    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(['cookieName']);
    const auth = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const userService = new UserService()
    const location = useLocation();
    //redirection through react component
    const state = location.state;
    // redirectio from a parameter in the url outside from react app
    const params = new URLSearchParams(location.search);
    const redirectPath = params.get('redirect');
    console.log("redirectPath", redirectPath)

    const verify_user_connected = async (csrf) => {
        console.log("token in verify_user_conencted", csrf.token)
        await userService.verifyUserConnexion(csrf)
    }

    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
    } = useForm()

    function get_csrf_token() {
        api.get("/get_csrf_token")
            .then((response, resolve) => {
                console.log("get_csrf_token", response.data)
                csrf.load_hashed_csrf_token()
                csrf.verify_token()
                verify_user_connected(csrf)
            })
    }

    function onSubmit(values, errors) {
        console.log(values)
        console.log(errors)
        api.post("/login", {
            "email": values.email,
            "password": values.password,
        }, {
            withCredentials: true,
            headers: {
                "csrf-token": csrf.token,
            },
        })
            .then((response, resolve) => {

                if (response.data.present == "true") {
                    dispatch(loginAction({
                        "firstName": response.data.firstName,
                        "lastName": response.data.lastName,
                        "id": response.data.user_id,
                        "email": response.data.email
                    }))
                    dispatch(updateIsAuthenticated(true))

                    let has_company = false
                    userService.hasCompany(csrf).then(data => {
                        console.log("has_company", data)
                        dispatch(checkCompany(data))
                    }).catch((error) => console.log(error))
                    userService.hasAwsAccount(csrf).then(data => {
                        dispatch(checkAWSConnexion(data))
                    }).catch((error) => console.log(error))
                    dispatch(updateIsAuthLoading(false))
                    dispatch(updateIsLoggedInWithGoogle(false))
                    if (state != null)
                        navigate("/connexion", { state: { redirect: state.redirect } })
                    else if (redirectPath != null)
                        navigate("/connexion", { state: { redirect: redirectPath } })
                    else
                    navigate("/connexion")

                }
                else
                    toast.error("User non present in the database!", {
                        position: "top-right",
                        theme: "colored",

                    });

            })
            .catch((error) => {
                console.log(error);
            })

    }

    const login = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            console.log(tokenResponse);
    // fetching userinfo can be done on the client or the server

            const userInfo = axios
                .get('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: { Authorization: `Bearer ${tokenResponse.access_token}` }
                })
                .then(res => {
                    const user = res.data;
                    api.post("/login", {
                        "idToken": user.sub,
                    }, {
                        headers: {
                            "Content-Type": "application/json",
                            "csrf-token": csrf.token,
                        },
                    })
                        .then((response, resolve) => {
                            // console.log(response.data)
                            if (response.data.present != undefined && response.data.present == "true") {
                                localStorage.setItem("firstName", response.data.firstName)
                                localStorage.setItem("lastName", response.data.lastName)
                                localStorage.setItem("user_id", response.data.user_id)
                                dispatch(loginAction({
                                    "firstName": response.data.firstName,
                                    "lastName": response.data.lastName,
                                    "id": response.data.user_id,
                                    "email": response.data.email
                                }))
                                dispatch(updateIsAuthenticated(true))
                                let has_company = false
                                userService.hasCompany(csrf).then(data => {
                                    has_company = data
                                    console.log("has_company", has_company)
                                    dispatch(checkCompany(has_company))
                                    console.log(auth)
                                }).catch((error) => console.log(error))

                                userService.hasAwsAccount(csrf).then(data => {
                                    dispatch(checkAWSConnexion(data))
                                }).catch((error) => console.log(error))
                                userService.getAvatar(csrf).then(data => {
                                    if (data["image"] != "") {
                                        const binaryData = hexStringToBinary(data["image"]);
                                        const blob = new Blob([binaryData], { type: 'image/jpeg' });
                                        let avatarUrl = URL.createObjectURL(blob)
                                        dispatch(updateAvatar(avatarUrl))
                                    }
                                }).catch((error) => console.log(error))
                                dispatch(updateIsAuthLoading(false))
                                dispatch(updateIsLoggedInWithGoogle(true))
                                if (state != null)
                                    navigate("/connexion", { state: { redirect: state.redirect } })
                                else if (redirectPath != null)
                                    navigate("/connexion", { state: { redirect: redirectPath } })
                                else
                                    navigate("/connexion")
                            }
                            else {

                                console.log("ici", response)
                                toast.error("User non present in the database!", {
                                    position: "top-right",
                                    theme: "colored",

                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                });
        }
    });



    useEffect(() => {
        get_csrf_token()
        if (auth.isAuthenticated && auth.cookieSetIntervalId == null) {
            let intervalId = checkUserConnexion(csrf, api)
            dispatch(updateCookieSetIntervalId(intervalId))
        }

    }, []);

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <ToastContainer />
            <Flex
                maxW={{ base: '100%', md: 'max-content' }}
                w="100%"
                mx={{ base: 'auto', lg: '0px' }}
                me="auto"
                h="100%"
                alignItems="start"
                justifyContent="center"
                mb={{ base: '30px', md: '60px' }}
                px={{ base: '25px', md: '0px' }}
                mt={{ base: '40px', md: '14vh' }}
                flexDirection="column"
            >
                <Box me="auto">
                    <Heading color={textColor} fontSize="36px" mb="10px">
                        Sign In
                    </Heading>
                    <Text
                        mb="36px"
                        ms="4px"
                        color={textColorSecondary}
                        fontWeight="400"
                        fontSize="md"
                    >
                        Enter your email and password to sign in!
                    </Text>
                </Box>
                <Flex
                    zIndex="2"
                    direction="column"
                    w={{ base: '100%', md: '420px' }}
                    maxW="100%"
                    background="transparent"
                    borderRadius="15px"
                    mx={{ base: 'auto', lg: 'unset' }}
                    me="auto"
                    mb={{ base: '20px', md: 'auto' }}
                >
                    <Button
                        fontSize="sm"
                        me="0px"
                        mb="26px"
                        py="15px"
                        h="50px"
                        borderRadius="16px"
                        bg={googleBg}
                        color={googleText}
                        fontWeight="500"
                        _hover={googleHover}
                        _active={googleActive}
                        _focus={googleActive}
                        onClick={() => login()}
                    >
                        <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
                        Sign in with Google
                    </Button>



                    <Flex align="center" mb="25px">
                        <HSeparator />
                        <Text color="gray.400" mx="14px">
                            or
                        </Text>
                        <HSeparator />
                    </Flex>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate >
                        <FormControl >
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px"
                            >
                                Email<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: '0px', md: '0px' }}
                                type="email"
                                placeholder="mail@simmmple.com"
                                mb="4px"
                                fontWeight="500"
                                size="lg"
                                className={errors.email ? "!border-red-600	" : ""}
                                {...register('email', {
                                    required: "This is required",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "invalid email address"
                                    }
                                })}
                            />
                            <p class={errors.email ? "text-red-500 text-sm mb-15" : ""}> {errors.email && errors.email?.message}</p>

                            <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                display="flex"
                            >
                                Password<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <InputGroup size="md">
                                <Input
                                    isRequired={true}
                                    fontSize="sm"
                                    placeholder="Min. 8 characters"
                                    mb="4px"
                                    size="lg"
                                    type={show ? 'text' : 'password'}
                                    variant="auth"
                                    className={errors.password ? "!border-red-600	" : ""}
                                    {...register('password', {
                                        required: 'This is required',
                                        minLength: { value: 8, message: 'Minimum length should be 8' },
                                        pattern: {
                                            value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/,
                                            message: 'Password must contain at least one uppercase letter, one lowercase letter, and one number',
                                        },

                                    })}
                                />

                                <InputRightElement display="flex" alignItems="center" mt="4px">
                                    <Icon
                                        color={textColorSecondary}
                                        _hover={{ cursor: 'pointer' }}
                                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                        onClick={handleClick}
                                    />
                                </InputRightElement>
                            </InputGroup>
                            <p class={errors.password ? "text-red-500 text-sm mb-25" : ""}> {errors.password && errors.password?.message}</p>

                            <Flex justifyContent="space-between" align="center" mb="24px">
                                <FormControl display="flex" alignItems="center">
                                    <Checkbox id="remember-login" colorScheme="brand" me="10px" />
                                    <FormLabel
                                        htmlFor="remember-login"
                                        mb="0"
                                        fontWeight="normal"
                                        color={textColor}
                                        fontSize="sm"
                                    >
                                        Keep me logged in
                                    </FormLabel>
                                    <FormErrorMessage>
                                        {errors.name && errors.name.message}
                                    </FormErrorMessage>
                                </FormControl>
                                <NavLink to="/auth/forgot-password">
                                    <Text
                                        color={textColorBrand}
                                        fontSize="sm"
                                        w="124px"
                                        fontWeight="500"
                                    >
                                        Forgot password?
                                    </Text>
                                </NavLink>
                            </Flex>
                            <Button
                                fontSize="sm"
                                variant="brand"
                                fontWeight="500"
                                w="100%"
                                h="50"
                                mb="24px"
                                isLoading={isSubmitting}
                                type='submit'


                            >
                                Sign In
                            </Button>
                        </FormControl>
                    </form>

                    <Flex
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="start"
                        maxW="100%"
                        mt="0px"
                    >
                        <Text color={textColorDetails} fontWeight="400" fontSize="14px">
                            Not registered yet?
                            <NavLink to="/auth/sign-up/default">
                                <Text
                                    color={textColorBrand}
                                    as="span"
                                    ms="5px"
                                    fontWeight="500"
                                >
                                    Create an Account
                                </Text>
                            </NavLink>
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default SignIn;