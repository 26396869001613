import { createSlice } from '@reduxjs/toolkit'
import UserService from 'UserService';

// const userService = new UserService()

export const userSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuthenticated: false,
        hasCompany: false,
        hasAwsAccount: false,
        awsRole: null,
        user: null,
        company: null,
        isAuthLoading: true,
        isLoggedInWithGoogle: false,
        cookieSetIntervalId: null,
        avatar: null
    },
    reducers: {
        loginAction: (state, action) => {
            state.isAuthenticated = true;
            state.user = action.payload
            console.log("user in loginAction", state.user)
        },
        logoutAction: (state) => {
            state.isAuthenticated = false;
            state.user = null
        },
        updateUser: (state, action) => {
            console.log("action payload in user", action.payload)
            state.user = action.payload
            console.log("state.user", state.user)
        },
        checkCompany: (state, action) => {
            console.log("action payload in checkcompany", action.payload)
            state.hasCompany = action.payload
        },
        checkAWSConnexion: (state, action) => {
            state.hasAwsAccount = action.payload.hasAwsAccount
            state.awsRole = action.payload.awsRole
        },
        updateIsAuthLoading: (state, action) => {
            console.log("action payload in checkcompany", action.payload)
            state.isAuthLoading = action.payload
        },
        updateIsLoggedInWithGoogle: (state, action) => {
            console.log("action payload in checkcompany", action.payload)
            state.isLoggedInWithGoogle = action.payload
        },
        updateCompany: (state, action) => {
            state.company = action.payload
        },
        updateCookieSetIntervalId(state, action) {
            state.cookieSetIntervalId = action.payload
        },
        updateAvatar(state, action) {
            state.avatar = action.payload
        },
        updateIsAuthenticated(state, action) {
            state.isAuthenticated = action.payload
        }


    },
})

// Action creators are generated for each case reducer function
export const { loginAction, logoutAction, checkCompany, checkAWSConnexion, updateUser, updateIsAuthLoading, updateIsLoggedInWithGoogle, updateCompany,
    updateCookieSetIntervalId, updateAvatar, updateIsAuthenticated } = userSlice.actions

export default userSlice.reducer