import { createSlice } from '@reduxjs/toolkit'
import UserService from 'UserService';
import {
    barChartDataWeeklyRevenue,
    barChartOptionsWeeklyRevenue,
} from "variables/charts";

// const userService = new UserService()

export const chartSlice = createSlice({
    name: 'chart',
    initialState: {
        services: [],
        service: "All",
        regions: [],
        region: "All",
        instances: [],
        instance: "none",
        data: [],
        categories: [],
        granularity: "Daily",
        dateRange: {},
        isChartLoading: true,
    },
    reducers: {
        updateServices: (state, action) => {
            state.services = action.payload

        },
        updateService: (state, action) => {
            state.service = action.payload

        },
        updateRegions: (state, action) => {
            state.regions = action.payload

        },
        updateRegion: (state, action) => {
            state.region = action.payload

        },
        updateInstances: (state, action) => {
            state.instances = action.payload
        },
        updateInstance: (state, action) => {
            state.instance = action.payload
        },
        updateData: (state, action) => {
            state.data = action.payload
            console.log("chartData in ChartSlice", state.data)
        },
        updateCategories: (state, action) => {
            // state.chartOptions = Object.assign(state.chartOptions, action.payload)
            state.categories = action.payload
            console.log("chartCategories in ChartSlice", state.categories)
            console.log("manger chartslice")

        },
        updateGranularity: (state, action) => {
            state.granularity = action.payload

        },
        updateDateRange: (state, action) => {
            state.dateRange = action.payload

        },
        updateIsChartLoading: (state, action) => {
            state.isChartLoading = action.payload

        },

    },
})

// Action creators are generated for each case reducer function
export const { updateServices, updateService, updateRegions, updateRegion, updateInstances, updateInstance, updateData, updateCategories, updateDateRange, updateGranularity, updateIsChartLoading } = chartSlice.actions

export default chartSlice.reducer