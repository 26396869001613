import api from "api"
import { useStore, useSelector, useDispatch } from 'react-redux';
import { loginAction, logoutAction } from 'features/user/userSlice'


class UserService {

    // store = useStore()
    // store_use = useSelector((state) => state.user.value)

    // dispatch = useDispatch()


    constructor() {

    }

    getUser = async (csrf) => {
        const response = await api.get("/getUser", {
            headers: {
                "csrf-token": csrf.token,
            }
        })
        return response.status == 200 ? response.data : {}
    }

    isUserConnected = async (csrf) => {
        const response = await api.get("/is_user_connected", {
            headers: {
                "csrf-token": csrf.token,
            }
        })
        if (response.status == 200) {
            if (response.data["connected"] == "true")
                return true
        }
        return false
    }

    verifyUserConnexion = async (csrf) => {
        // sessionStorage.setItem("previous_url", window.location.href)
        console.log("Csrf token in verifyUserConnexion", csrf.token)
        const connected = await this.isUserConnected(csrf)
        console.log("connected", connected)
        // if (!connected && window.location.href != (window.location.origin + "/auth/sign-in/default"))
        //     // window.location.href = window.location.origin + "/auth/sign-in/default"
        //     alert("1")
        // else if (connected && window.location.href == (window.location.origin + "/auth/sign-in/default")) {
        //     // let previous_url = sessionStorage.getItem("previous_url")
        //     // if (previous_url != null && previous_url != "")
        //     //     window.location.href = previous_url
        //     // else
        //     // window.location.href = window.location.origin + "/connexion"
        //     alert("2")

        // }
        if (connected && window.location.href != (window.location.origin + "/auth/sign-in/default")) {
            const user = await this.getUser(csrf)
            console.log("user in verifyUserConnexion", user)
            return user
        }
    }


    hasCompany = async (csrf) => {
        const response = await api.get("/has_company", {
            headers: {
                "csrf-token": csrf.token,
            }
        })
        if (response.status == 200) {
            if (response.data["has_company"] == "true")
                return true
            return false
        }
        return false
    }

    hasAwsAccount = async (csrf) => {
        const response = await api.get("/verify_aws_connexion", {
            headers: {
                "csrf-token": csrf.token,
            }
        })
        if (response.status == 200) {
            if (response.data["role"] != null && response.data["role"] != undefined)
                return { hasAwsAccount: true, awsRole: response.data["role"] }

        }
        return { hasAwsAccount: false, awsRole: null }
    }

    get_company = async (csrf) => {
        const response = await api.get("/get_company", {
            headers: {
                "csrf-token": csrf.token,
            }
        })
        if (response.status == 200) {
            console.log(response.data)
            return response.data
        }
        return {}
    }
    hasLoggedInWithGoogle = async (csrf) => {
        const response = await api.get("/has_logged_in_with_google", {
            headers: {
                "csrf-token": csrf.token,
            }
        })
        if (response.status == 200) {
            if (response.data["has_logged_with_google"] == "true")
                return true
            return false
        }
        return false
    }
    getAccount = async (csrf) => {
        const response = await api.get("/getUser", {
            headers: {
                "csrf-token": csrf.token,
            }
        })
        return response.status == 200 ? response.data : {}
    }

    getAvatar = async (csrf) => {
        const response = await api.get("/get_avatar", {
            headers: {
                "csrf-token": csrf.token,
            }
        })
        return response.status == 200 ? response.data : {}
    }

    changeGmailAddress = async (csrf, email) => {
        const response = await api.post("/email_change_request",
            {
                "email": email
            },
            {
                headers: {
                    "csrf-token": csrf.token,
                }
            })
        return response.status == 200 ? response.data : {}
    }

}



export default UserService



