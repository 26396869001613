// Chakra imports
import { Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import IconBox from 'components/icons/IconBox';
import { DashCurveDown, DashCurveUp } from 'components/icons/Icons';

// Assets
import { MdCheckCircle, MdComment, MdPersonAddAlt1 } from 'react-icons/md';

export default function Conversion(props: { [x: string]: any }) {
  const { ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const brandColor = useColorModeValue('brand.500', 'white');
  const dashColor = useColorModeValue('brand.500', 'whiteAlpha.500');
  const shadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'unset',
  );
  const completeShadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'inset 0px 4px 4px rgba(255, 255, 255, 0.2)',
  );
  const boxBg = useColorModeValue(
    'white',
    'linear-gradient(180deg, #1F2A4F 0%, #18224D 50.63%, #111C44 100%)',
  );
  return (
    <Card p="30px"  {...rest}>
      <Text color={textColor} fontSize="2xl" fontWeight="700" mb="10px">
        Set Up and Manage Alerts
      </Text>
      <Text color="secondaryGray.600" fontSize="md" fontWeight="400" mb="70px">
        Set different Alerts to be constantly up to date with your cloud consumption
      </Text>
      <Flex
        position="relative"
        direction={{ base: 'column', md: 'row' }}
        justifyContent="center"
      >
        <Flex direction="column" align="center" justify="center">
          <IconBox
            mb="16px"
            w="100px"
            h="100px"
            bg={boxBg}
            shadow={shadow}
            boxShadow={completeShadow}
            icon={<Icon w="38px" h="38px" as={MdComment} color={brandColor} />}
          />
          <Text
            textAlign="center"
            color={textColor}
            fontSize="xl"
            fontWeight="700"
            mb="10px"
          >
            Choose Alerts
          </Text>
          <Text
            textAlign="center"
            color="secondaryGray.600"
            fontSize="sm"
            fontWeight="400"
            maxW="278px"
            mb="70px"
          >
            Provide the AWS Monthly budget and usage notifications you want to receive          </Text>
        </Flex>
        <Flex mx="60px" direction="column" align="center" justify="center">
          <IconBox
            mb="16px"
            w="100px"
            h="100px"
            bg={boxBg}
            shadow={shadow}
            boxShadow={completeShadow}
            icon={
              <Icon w="38px" h="38px" as={MdPersonAddAlt1} color={brandColor} />
            }
          />
          <Text
            textAlign="center"
            color={textColor}
            fontSize="xl"
            fontWeight="700"
            mb="10px"
          >
            Customize Alerts          </Text>
          <Text
            textAlign="center"
            color="secondaryGray.600"
            fontSize="sm"
            fontWeight="400"
            maxW="278px"
            mb="70px"
          >
            Adjust notification triggers to align with your budgeting need.          </Text>
        </Flex>
        <Flex direction="column" align="center" justify="center">
          <IconBox
            mb="16px"
            w="100px"
            h="100px"
            bg={boxBg}
            shadow={shadow}
            boxShadow={completeShadow}
            icon={
              <Icon w="38px" h="38px" as={MdCheckCircle} color={brandColor} />
            }
          />
          <Text
            textAlign="center"
            color={textColor}
            fontSize="xl"
            fontWeight="700"
            mb="10px"
          >
            Notification Settings          </Text>
          <Text
            textAlign="center"
            color="secondaryGray.600"
            fontSize="sm"
            fontWeight="400"
            maxW="278px"
            mb="70px"
          >
            Decide how you want to receive alerts, whether through email, Slack, SMS, or other integrations          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}
