import { updateIsAuthenticated } from 'features/user/userSlice';
import { getCookie } from 'functions/functions';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from "react-router-dom"

const ProtectedRoute = ({ children }) => {
    const auth = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    let location = useLocation();

    let klawdy_token = getCookie("klawdy_token")
    if (klawdy_token == null || klawdy_token == undefined) {
        dispatch(updateIsAuthenticated(false))
        return <Navigate to="/auth/sign-in/default" state={{ redirect: location.pathname }} replace />
    }
    else {
        dispatch(updateIsAuthenticated(true))
        return children
    }

};

export default ProtectedRoute;