// Chakra imports
import { Flex, FormControl, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import SwitchField from 'components/fields/SwitchField';
import { useState } from 'react';

export default function Newsletter(props: {
	changeIsChecked?: React.Dispatch<React.SetStateAction<boolean>>,
	isAnomalyChecked?: boolean,
	changeIsAnomalyChecked?: React.Dispatch<React.SetStateAction<boolean>>,
	isChecked?: boolean
	[x: string]: any
}) {
	const { changeIsChecked, isChecked, isAnomalyChecked, changeIsAnomalyChecked, ...rest } = props;
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

	const displayAlert: () => void = () => {
		if (isChecked)
			changeIsChecked(false)
		else changeIsChecked(true)
		console.log("isChecked", isAnomalyChecked)


	}

	const displayAnomaly: () => void = () => {
		if (isAnomalyChecked)
			changeIsAnomalyChecked(false)
		else changeIsAnomalyChecked(true)
		console.log("isAnomalyChecked", isAnomalyChecked)

	}
	// Chakra Color Mode
	return (
		<FormControl w={{
			base: "100%",
			md: "34%"
		}}>
			<Card p='30px' mb='20px' {...rest}>
				<Flex mb='40px' justify='space-between' align='center'>
					<Text fontSize='2xl' color={textColorPrimary} fontWeight='bold'>
						Alerts
					</Text>
				</Flex>
					<SwitchField
						mb='25px'
						me='30px'
						id='1'
					label='Budget Alert'
					desc=''
					onSwitchChange={displayAlert}
					isChecked={isChecked}
					fontSize="sm"
				/>
				<SwitchField
					mb='25px'
					me='30px'
					id='2'
					label='Anomaly Alert'
					desc=''
					onSwitchChange={displayAnomaly}
					isChecked={isAnomalyChecked}
					fontSize="sm"
				/>
			</Card>
		</FormControl>
	);
}
