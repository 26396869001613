


import { toast } from 'react-toastify';

export const getCookie = (cookieKey: string) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split(';').map(cookie => cookie.trim());

    for (const cookie of cookies) {
        const [name, value] = cookie.split('=');
        if (name === cookieKey) {
            return decodeURIComponent(value);
        }
    }

    return null; // Return null if cookie with given key is not found
}

export const checkUserConnexion = (csrf: any, api: any) => {
    const intervalId = setInterval(() => {
        let klawdy_token = getCookie("klawdy_token")
        console.log("cookie interval is running")
        if (klawdy_token == null || klawdy_token == undefined) {
            clearInterval(intervalId)
            console.log("interval is stopped")
            toast.error("cookies have been deleted", {
                position: "top-right",
                theme: "colored",

            });
            setTimeout(() => {
                api.get("/logout", {
                    withCredentials: true,
                    headers: {
                        "csrf-token": csrf.token
                    }
                }).then((response: any) => {
                    if (response.status == 200 && response.data == "Logged out successfully") {
                        window.location.href = window.location.origin + "/auth/sign-in/default"
                    }
                    console.log("logout response", response)
                }).catch((error: any) => {
                    console.log(error)
                })
            }, 3000);


        }

    }, 5000)
    return intervalId

}

export const hexStringToBinary = (hexString: any) => {
    const bytes = [];
    for (let i = 0; i < hexString.length; i += 2) {
        bytes.push(parseInt(hexString.substr(i, 2), 16));
    }
    return new Uint8Array(bytes);
};