/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
// Custom components
import Balance from 'views/admin/dashboards/default/components/Balance';
import DailyTraffic from 'views/admin/dashboards/default/components/DailyTraffic';
import MostVisitedTable from 'views/admin/dashboards/default/components/MostVisitedTable';
import { VSeparator } from 'components/separator/Separator';
import OverallRevenue from 'views/admin/dashboards/default/components/OverallRevenue';
import ProfitEstimation from 'views/admin/dashboards/default/components/ProfitEstimation';
import ProjectStatus from 'views/admin/dashboards/default/components/ProjectStatus';
import YourCard from 'views/admin/dashboards/default/components/YourCard';
import YourTransfers from 'views/admin/dashboards/default/components/YourTransfers';
import tableDataMostVisited from 'views/admin/dashboards/default/variables/tableDataMostVisited';
import React, { useEffect, useContext, useState, createContext } from "react";
import axios from 'axios';
import Widget from 'components/widget/Widget';
import { MdBarChart } from 'react-icons/md';
import { IoDocuments } from 'react-icons/io5';
import { GoogleLogout } from '@react-oauth/google';
import "../../../../assets/css/style.css"
import Lottie from "react-lottie";
import * as cloud from "../../../../assets/img/others/Cloud.json";



// Chakra imports
import {
  Flex,
  SimpleGrid,
  Text,
  Icon,
  Image,
  Box,
  Button,
  DarkMode,
  IconButton,
  Input,
  useColorModeValue,
  Grid,
  Select,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Spacer,

} from '@chakra-ui/react';

// Assets
import { MdOutlineBarChart, MdPerson, MdFileCopy, MdAttachMoney } from 'react-icons/md';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import { IoPaperPlaneOutline } from 'react-icons/io5';
import { MdOutlineFavoriteBorder } from 'react-icons/md';
import FakeBarChart from 'assets/img/account/FakeBarChart.png';
import storyImage from 'assets/img/profile/storyImage.png';

// Custom components
import Statistics from 'views/admin/main/account/application/components/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import ManagementTable from 'views/admin/main/account/application/components/ManagementTable';
import tableDataManagement from 'views/admin/main/account/application/variables/tableDataManagement';
import WeeklyRevenue from './components/WeeklyRevenue';

import api from "api"
import csrf from "csrf"
import UserService from "UserService"
import PieCard from 'views/admin/dashboards/rtl/components/PieCard';


//Data
import {
  barChartDataWeeklyRevenue,
  barChartOptionsWeeklyRevenue,
} from "variables/charts";


import { useNavigate } from 'react-router-dom';
import { verify } from 'crypto';
import { isAwaitExpression } from 'typescript';
import { useDispatch, useSelector } from 'react-redux';
import { updateTotal, updateLastMonthCost, updateIsTotalLoading, updateCurrentMonthCost, updateIsCurrentMonthLoading, updateIsLastMonthLoading } from 'features/consumption/consumptionSlice'


const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: cloud.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function Default() {

  // Chakra Color Mode
  const paleGray = useColorModeValue('#DFE6F6', 'whiteAlpha.100');

  const [url, setUrl] = useState("https://dolphin-app-h9ivo.ondigitalocean.app/")
  const [total, setTotal] = useState("3456")
  const [currentMonth, setCurrentMonth] = useState('564.83')
  const [lastMonth, setLastMonth] = useState('798.98')
  const [globalPercentage, setGlobalPercentage] = useState('10')
  const [lastMonthPercentage, setLastMonthPercentage] = useState('0')
  const [currentMonthPercentage, setCurrentMonthPercentage] = useState('0')
  const [arn, setRrn] = useState(process.env.REACT_APP_ARN)
  const [status, setStatus] = useState(0);
  const navigate = useNavigate();
  const [services, setServices] = useState([])
  const [service, setService] = useState("All")
  const [region, setRegion] = useState("All")
  const [regions, setRegions] = useState([])
  const [instance, setInstance] = useState("none")
  const [instances, setInstances] = useState([])
  const [serviceIndex, SetserviceIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [chartData, setChartData] = useState([])
  const [chartOptions, setChartOptions] = useState({})
  const iconBg = useColorModeValue('secondaryGray.300', 'navy.700');
  const iconColor = useColorModeValue('brand.500', 'white');
  const [granularity, SetGranularity] = useState("Daily");
  const [dateRange, setDateRange] = useState({});
  const [awsAccountRole, setAWSAccountRole] = useState("None")
  const [isOpenModal, setIsOpenModal] = useState(false)
  const userService = new UserService()
  const auth = useSelector((state) => state.auth)
  const consumption = useSelector((state) => state.consumption)
  const dispatch = useDispatch()

  const [isAWSConnected, setIsAWSConnected] = useState(false);



  //modal
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });



  
  function handdleServiceChange(e) {
    console.log("sercice name", e.target.value);
    setService(e.target.value)
    setIsLoading(true)

  }

  function handleRegionChange(e) {
    console.log("Region names", e.target.value);
    setRegion(e.target.value)
    setIsLoading(true)

  }

  function handleInstanceChange(e) {
    console.log("instance names", e.target.value);
    setInstance(e.target.value)
    setIsLoading(true)

  }

  function redirectOnboarding() {
    navigate("/admin/onboarding")
  }


  const get_cost_usage = () => {
    api.get('/cost_usage', {
      withCredentials: true,
      headers: {
        "csrf-token": csrf.token
      }
    })
      .then((response) => {
        let costs = response.data
        console.log("total costs", costs);
        barChartOptionsWeeklyRevenue["xaxis"]["categories"] = []
        barChartDataWeeklyRevenue[0]["data"] = []
        for (let index = 0; index < costs.length; index++) {
          barChartOptionsWeeklyRevenue["xaxis"]["categories"][index] = granularity != "Monthly" ? costs[index]["time"].split("-")[2] + "/" + costs[index]["time"].split("-")[1] : costs[index]["time"];
          barChartDataWeeklyRevenue[0]["data"][index] = parseFloat(costs[index]["total"]).toFixed(2) || 0;

        }
        setChartOptions({ ...barChartOptionsWeeklyRevenue })
        setChartData([...barChartDataWeeklyRevenue])
        console.log("barChartOptionsWeeklyRevenue", barChartOptionsWeeklyRevenue)
        console.log("barChartDataWeeklyRevenue", barChartDataWeeklyRevenue)
        setIsLoading()

        // Handle data
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const getServicesRegions = () => {
    api.get('/filter_list', {
      withCredentials: true,
      headers: {
        "csrf-token": csrf.token
      }
    })
      .then((response) => {
        console.log("services", response.data["services"]);
        console.log("regions", response.data["regions"]);
        setServices(["All", ...response.data["services"]])
        setRegions(["All", ...response.data["regions"]])
      })
      .catch((error) => {
        console.log(error);
      })
  }


  const get_cost_by_filter = () => {
    api.post('/cost_by_filter', {
      "service": service,
      "region": region,
      "granularity": granularity.toUpperCase(),
      "instance": instance,
      "dateRange": dateRange
    }, {
      withCredentials: true,
      headers: {
        "csrf-token": csrf.token
      }
    })
      .then((response) => {
        let costs = response.data
        console.log("costs by filter", costs);
        barChartOptionsWeeklyRevenue["xaxis"]["categories"] = []
        barChartDataWeeklyRevenue[0]["data"] = []
        for (let index = 0; index < costs.length; index++) {
          barChartOptionsWeeklyRevenue["xaxis"]["categories"][index] = granularity != "Monthly" ? costs[index]["time"].split("-")[2] + "/" + costs[index]["time"].split("-")[1] : costs[index]["time"];;
          barChartDataWeeklyRevenue[0]["data"][index] = parseFloat(costs[index]["total"]).toFixed(2) || 0;

        }
        setChartOptions({ ...barChartOptionsWeeklyRevenue })
        setChartData([...barChartDataWeeklyRevenue])
        console.log("barChartOptionsWeeklyRevenue", barChartOptionsWeeklyRevenue)
        console.log("barChartDataWeeklyRevenue", barChartDataWeeklyRevenue)
        setIsLoading(false)


      })
      .catch((error) => {
        console.log(error);
      })
  }
  const verify_aws_connexion = () => {
    api.get("/verify_aws_connexion", {
      withCredentials: true,
      headers: {
        "csrf-token": csrf.token
      }
    }).then((response) => {
      if (response.status == 200) {
        if ("role" in response.data) {
          setIsAWSConnected(true)
          setAWSAccountRole(response.data.role)
          onClose()
          console.log(response.data)
        }
        else {
          setIsAWSConnected(false)
          console.log(response.data)
          onOpen()
        }
        console.log(response.data)
      }
    })
  }


  useEffect(() => {

    const getTotalCost = async () => {
      try {
        let response = await api.get('/total_cost', {
          withCredentials: true, headers: {
            "csrf-token": csrf.token
          }
        })
        if (response.status == 200) {
          console.log("total", response.data["total"]);
          setTotal(parseFloat(response.data["total"]).toFixed(2))
          let tmp = parseFloat(response.data["total"]).toFixed(2).toString()
          console.log("total in string conversion", tmp)
          dispatch(updateTotal(tmp))
          dispatch(updateIsTotalLoading(false))
          console.log("total in redux", consumption.total)
        }
      } catch (error) {
        console.log(error)
      }

    }

    async function getLastMonthAndCurrentMonthCosts(csrf) {
      try {
        let response = await api.get('/get_percentage', {
          withCredentials: true,
          headers: {
            "csrf-token": csrf.token
          }
        })
        if (response.status == 200) {
          console.log("get_percentage data", response.data);
          setCurrentMonth(parseFloat(response.data[1]["total"]).toFixed(2))
          setLastMonth(parseFloat(response.data[0]["total"]).toFixed(2))
          dispatch(updateIsCurrentMonthLoading(false))
          dispatch(updateCurrentMonthCost(parseFloat(response.data[1]["total"]).toFixed(2).toString()))
          dispatch(updateIsLastMonthLoading(false))
          dispatch(updateLastMonthCost(parseFloat(response.data[0]["total"]).toFixed(2).toString()))
          console.log("response in getlastMonthAndCurrentMonthCosts", response)
        }


      } catch (error) {
        console.log(error)
      }

    }


    csrf.load_hashed_csrf_token()
    csrf.verify_token()
    // userService.verifyUserConnexion(csrf)
    // userService.verifyUserConnexion()
    // verify_aws_connexion()
    console.log("isOpen", isOpen)
    // verify_user_connected()
    if (auth.isAuthLoading == false) {
      console.log("boire")
      console.log("boire auth", auth)
      setTimeout(() => {

      }, 2000)
      if (auth.hasAwsAccount) {
        if (consumption.total == "0" || consumption.currentMonthCost == '0' || consumption.lastMonthCost == '0') {
          console.log("ici")
          getTotalCost(csrf)
          getLastMonthAndCurrentMonthCosts(csrf)
        }
        getServicesRegions()
        get_cost_usage()
        onClose()
      }
      else {
        onOpen()
        console.log("manger")
      }
    }
    console.log("auth in index.js", auth)


  }, [auth?.isAuthLoading]);


  // useEffect(() => {
  //   console.log("isAWSConnected", isAWSConnected)
  //   verify_aws_connexion()
  //   if (isAWSConnected) {
  //     getTotalCost()
  //     getServicesRegions()
  //     // getServices(url)
  //     get_cost_usage()
  //   }


  // }, [isAWSConnected])



  useEffect(() => {
    if (auth?.hasAwsAccount) {
      get_cost_by_filter()
    }
    console.log("Crs token in index page", csrf.token)
  }, [service,region,granularity,instance,dateRange]);



  return (

    <Flex pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction="column" width="stretch">
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent mx='8px' mt="9.75rem" bg='white' boxShadow='unset' w="700px" h="500px" borderRadius='30px'
          >
            <ModalBody p='0px' bg='white'  >
              <Flex direction="column" justifyContent="center" alignItems="center" color="white" h="100%" w="100%">
                <Lottie options={defaultOptions} height={200} width={200} />
                <Box textAlign="center" px={{ sm: '5', lg: 'l7', xl: '20' }}>
                  <Text color="black" fontSize={{ sm: 'xl', lg: 'lg', xl: 'xl' }}>Please add an <strong>AWS</strong> account to check your cloud expenses and view potential savings</Text>
                </Box>
                <Box>
                  <Flex justify="space-between" mt="24px" mb="30px" gap="10">
                    <Button
                      variant="darkBrand"
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                      onClick={redirectOnboarding}
                    >
                      Start
                    </Button>
                  </Flex>
                </Box>
              </Flex>

            </ModalBody>
          </ModalContent>
        </Modal>
        <SimpleGrid
          columns={{ sm: 1, md: 3, lg: 3, xl: 3, '2xl': 3 }}
          gap="20px"
          mb="30px"
        >
          <Flex>
            <Statistics
              focused={true}
              bgGradient="linear(to-b, brand.400, brand.600)"
              title={'Total Spent'}
              value={consumption?.total + " $"}
              isValueLoading={consumption?.isTotalLoading}
              detail={
                <Flex align="center">
                  {/* <Icon as={RiArrowUpSFill} color="white" /> */}
                  {/* <Text color="white" fontSize="sm" mx="3px" fontWeight="500">
                    {Math.floor(Math.random() * (15 - 4 + 1)) + 4} %
                  </Text>
                  <Text color="white" fontSize="sm" fontWeight="500">
                    Since last month
                  </Text> */}
                </Flex>
              }
              illustration={
                <IconBox
                  w="80px"
                  h="80px"
                  //   bg="linear-gradient(290.56deg, #868CFF -18.35%, #4318FF 60.45%)"
                  bgGradient="linear(to-b, brand.600, brand.400)"
                  icon={
                    <Icon
                      as={MdOutlineBarChart}
                      w="38px"
                      h="38px"
                      color="white"
                    />
                  }
                />
              }
            />
          </Flex>
          <Flex>
            <Statistics
              title={'Current Month'}
              value={consumption?.currentMonthCost + " $"}
              isValueLoading={consumption?.isCurrentMonthCostLoading}
              detail={
                <Flex align="center">
                  {/* <Icon as={RiArrowUpSFill} color="blue.600" />
                  <Text color="blue.600" fontSize="sm" mx="4px" fontWeight="700">
                    {currentMonthPercentage}%
                  </Text>
                  <Text
                    color="secondaryGray.600"
                    fontSize="sm"
                    fontWeight="500"
                  >
                    Since last month
                  </Text> */}
                </Flex>
              }
              illustration={
                <IconBox
                  w="80px"
                  h="80px"
                  bg={iconBg}
                  icon={
                    <Icon color={iconColor} as={MdAttachMoney} w="28px" h="28px" />
                  }
                />
              }
            />
          </Flex>
          <Flex>
            <Statistics
              title={'Last Month'}
              value={consumption?.lastMonthCost + " $"}
              isValueLoading={consumption?.isLastMonthCostLoading}
              detail={
                <Flex align="center">
                  {/* <Icon as={RiArrowUpSFill} color="blue.600" />
                  <Text color="blue.600" fontSize="sm" mx="4px" fontWeight="700">
                    {lastMonthPercentage}%
                  </Text>
                  <Text
                    color="secondaryGray.600"
                    fontSize="sm"
                    fontWeight="500"
                  >
                    Since last month
                  </Text> */}
                </Flex>
              }
              illustration={
                <IconBox
                  w="80px"
                  h="80px"
                  bg={iconBg}
                  icon={
                    <Icon color={iconColor} as={MdAttachMoney} w="28px" h="28px" />
                  }
                />
              }
            />
          </Flex>
        </SimpleGrid>
        <Flex direction={{
          base: "column",
          md: "column",
          lg: "row",
          xl: "row"
        }} h="550px">

          <WeeklyRevenue service={service} region={region} isLoading={isLoading} changeLoading={setIsLoading} chartData={chartData} chartOptions={chartOptions} granularity={granularity} changeGranularity={SetGranularity}
            changeDateRange={setDateRange} dateRange={dateRange}
            w={{
              base: "100%",
              md: "100%",
              lg: "66%",
              xl: "66%"
            }}
        />
          <PieCard ml={{
            base: '0px',
            md: '3px',
            lg: '5px',
            xl: '7px',
            '2xl': '0px'
          }}
            w={{
              base: "100%",
              md: "100%",
              lg: "33%",
              xl: "33%"
            }}
          />
        </Flex>


      </Flex>
    </Flex>
  );
}
